import {
  ModalHeader,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalBody,
  ModalFooter,
  Button,
  Spacer,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  useToast,
} from "@chakra-ui/react";

import moment from "moment";

function InseminationModal({ isOpen, onClose, addNew, newData, setNew }) {
  const toast = useToast();

  function showToastInput() {
    toast({
      title: "Insert Data in All Required Fields!",
      status: "warning",
      duration: 5000,
      isClosable: true,
    });
  }

  const checkValidInput = () => {
    if (
      newData.batch === "" ||
      newData.description === "" ||
      newData.d0 === ""
    ) {
      showToastInput();
      return false;
    }

    return true;
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign="center">New Insemination</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex>
            <FormControl p="1vw" isRequired>
              <FormLabel fontWeight="bold" fontSize="sm">
                Batch Number
              </FormLabel>
              <Input
                type="number"
                placeholder="Add Batch Number"
                value={newData && newData.batch ? newData.batch : ""}
                onChange={(text) => {
                  const copy = { ...newData };
                  copy.batch = text.target.value;
                  setNew(copy);
                }}
              />
            </FormControl>
          </Flex>
          <Flex>
            <FormControl p="1vw" isRequired>
              <FormLabel fontWeight="bold" fontSize="sm">
                Start Date
              </FormLabel>
              <Input
                type="date"
                placeholder="Select date"
                value={newData && newData.d0 ? newData.d0 : ""}
                onChange={(text) => {
                  const copy = { ...newData };
                  copy.d0 = text.target.value;
                  copy.d7 = moment(text.target.value)
                    .add(7, "days")
                    .format("YYYY-MM-DD");
                  copy.d9 = moment(text.target.value)
                    .add(9, "days")
                    .format("YYYY-MM-DD");
                  copy.d11 = moment(text.target.value)
                    .add(11, "days")
                    .format("YYYY-MM-DD");
                  copy.checkPregnance = moment(text.target.value)
                    .add(46, "days")
                    .format("YYYY-MM-DD");
                  setNew(copy);
                }}
              />
            </FormControl>
          </Flex>
          <Flex>
            <FormControl p="1vw" isReadOnly="true" isRequired>
              <FormLabel fontWeight="bold" fontSize="sm">
                Day 7
              </FormLabel>
              <Input
                type="date"
                placeholder="Select date"
                value={newData && newData.d7 ? newData.d7 : ""}
                onChange={(text) => {
                  const copy = { ...newData };
                  copy.d7 = text.target.value;
                  setNew(copy);
                }}
              />
            </FormControl>
          </Flex>
          <Flex>
            <FormControl p="1vw" isReadOnly="true" isRequired>
              <FormLabel fontWeight="bold" fontSize="sm">
                Day 9
              </FormLabel>
              <Input
                type="date"
                placeholder="Select date"
                value={newData && newData.d9 ? newData.d9 : ""}
                onChange={(text) => {
                  const copy = { ...newData };
                  copy.d9 = text.target.value;
                  setNew(copy);
                }}
              />
            </FormControl>
          </Flex>
          <Flex>
            <FormControl p="1vw" isReadOnly="true" isRequired>
              <FormLabel fontWeight="bold" fontSize="sm">
                Day 11
              </FormLabel>
              <Input
                type="date"
                placeholder="Select date"
                value={newData && newData.d11 ? newData.d11 : ""}
                onChange={(text) => {
                  const copy = { ...newData };
                  copy.d11 = text.target.value;
                  setNew(copy);
                }}
              />
            </FormControl>
          </Flex>
          <Flex>
            <FormControl p="1vw" isReadOnly="true" isRequired>
              <FormLabel fontWeight="bold" fontSize="sm">
                Check Pregnance Date
              </FormLabel>
              <Input
                type="date"
                placeholder="Select date"
                value={
                  newData && newData.checkPregnance
                    ? newData.checkPregnance
                    : ""
                }
                onChange={(text) => {
                  const copy = { ...newData };
                  copy.checkPregnance = text.target.value;
                  setNew(copy);
                }}
              />
            </FormControl>
          </Flex>
          <Flex>
            <FormControl p="1vw" isRequired>
              <FormLabel fontWeight="bold" fontSize="sm">
                Description
              </FormLabel>
              <Input
                type="text"
                placeholder="Add Description"
                value={
                  newData && newData.description ? newData.description : ""
                }
                onChange={(text) => {
                  const copy = { ...newData };
                  copy.description = text.target.value;
                  setNew(copy);
                }}
              />
            </FormControl>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button bg="green.500" onClick={onClose}>
            Cancel
          </Button>
          <Spacer />
          <Button
            bg="green.500"
            onClick={() => {
              if (checkValidInput()) {
                addNew();
                onClose();
              }
            }}
          >
            Add
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default InseminationModal;
