import { Flex, Link, Text } from "@chakra-ui/react";
import React from "react";
import moment from "moment";
import { RiDeleteBin6Line } from "react-icons/ri";
import { GrDocumentDownload } from "react-icons/gr";

function SingleFile({ fileData, deleteDoc }) {
  return (
    <Flex
      justify="space-between"
      flexDir="row"
      flexWrap="wrap"
      w="100%"
      h="fit-content"
      p="2"
      bg="green.200"
      borderRadius="10"
      border="1px solid green"
      m="1"
      alignItems="center"
    >
      <Text fontSize="md">Name: {fileData.data.documentName}</Text>
      <Text fontSize="md">
        Date: {moment(fileData.data.documentDate).format("DD/MM/YYYY")}
      </Text>
      <Flex>
        <Link href={fileData.data.documentUrl} isExternal>
          <GrDocumentDownload />
        </Link>
        <Flex
          ml="5"
          justify="flex-end"
          onClick={() => {
            deleteDoc(fileData.id);
          }}
        >
          <RiDeleteBin6Line />
        </Flex>
      </Flex>
    </Flex>
  );
}

export default SingleFile;
