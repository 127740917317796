import { Button, Flex, useDisclosure } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { db } from "../../firebase/firebase";
import moment from "moment";
import ResultTable from "../Modals/ResultTable";
import InseminationModal from "./NewInseminationModal";
import { addDoc, collection, doc, getDocs } from "firebase/firestore";
import { setCounterRedux } from "../../redux/slices/counterSlice";

const currentDate = moment().format("YYYY-MM-DD");

var new_date7 = moment(currentDate).add(7, "days").format("YYYY-MM-DD");
var new_date9 = moment(currentDate).add(9, "days").format("YYYY-MM-DD");
var new_date11 = moment(currentDate).add(11, "days").format("YYYY-MM-DD");
var new_date35 = moment(currentDate).add(46, "days").format("YYYY-MM-DD");

function Insemination() {
  const insemination2 = {
    batch: "",
    d0: currentDate,
    d7: new_date7,
    d9: new_date9,
    d11: new_date11,
    checkPregnance: new_date35,
    description: "N/A",
  };

  const insemination = {
    modalHeading: " New Insemination",
    editHeading: " Edit Insemination",
    modalLabel: [
      "Batch Number",
      "Day 0",
      "Day 7",
      "Day 9",
      "Day 11",
      "Check Pregance Date",
      "Description",
    ],
    modalPlaceholder: [
      "Batch Number",
      "Day 0",
      "Day 7",
      "Day 9",
      "Day 11",
      "Check Pregance Date",
      "Description",
    ],
    modalValue: [
      "batch",
      "d0",
      "d7",
      "d9",
      "d11",
      "checkPregnance",
      "description",
    ],

    type: ["number", "date", "date", "date", "date", "date", "text"],
    inseminationPath: {
      batch: "",
      d0: "",
      d7: "",
      d9: "",
      d11: "",
      checkPregnance: "",
      description: "N/A",
    },
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [newValue, setNewValue] = useState(insemination2);
  const [inseminationData, setInseminationData] = useState([]);
  const { farmName } = useSelector((state) => state.farmNameSelected);
  const { counter } = useSelector((state) => state.counterSelected);
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchData() {
      if (farmName !== "") {
        const farmNameRef = doc(db, "farms", farmName);
        const animalRef = collection(farmNameRef, "inseminations");
        const docSnap = await getDocs(animalRef);
        const data = [];
        docSnap.forEach((doc) => {
          data.push({ id: doc.id, data: doc.data() });
        });
        setInseminationData(data);
      }
    }
    fetchData();
  }, [farmName, counter]);

  const addIsemination = async () => {
    const farmNameRef = doc(db, "farms", farmName);
    const docRef = collection(farmNameRef, "inseminations");
    try {
      await addDoc(docRef, newValue);
      dispatch(setCounterRedux(counter + 1));
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  return (
    <>
      <Flex
        w="100%"
        flexWrap="wrap"
        flexDir="column"
        bgRepeat="no-repeat"
        bgSize="cover"
        bgColor="white"
      >
        <Flex
          flexWrap="wrap"
          flexDir="row"
          my="3"
          justifyContent="left"
          display="flex"
        >
          <Button
            width="fit-content"
            mx="1"
            my="1"
            color="white"
            bg="green.500"
            fontWeight="bold"
            fontSize="13"
            onClick={() => {
              onOpen();
            }}
          >
            New Insemination +
          </Button>
        </Flex>
        <Flex
          w="100%"
          justifyContent="center"
          display="flex"
          flexDirection="column"
        >
          <ResultTable
            w="100%"
            schema={insemination}
            data={inseminationData}
            currentDoc={"inseminations"}
          />
        </Flex>
      </Flex>
      <InseminationModal
        isOpen={isOpen}
        onClose={onClose}
        addNew={addIsemination}
        newData={newValue}
        setNew={setNewValue}
      />
    </>
  );
}

export default Insemination;
