import {
  Flex,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import React from "react";
import BatchAndAnimal from "../MyAnimals/BatchAndAminal";
import Insemination from "../MyAnimals/Insemination";
import Vaccination from "../MyAnimals/Vaccine";
import DocumentBills from "../MyDocuments/DocumentBills";
import DocumentInvoices from "../MyDocuments/DocumentInvoices";
import DocumentLegalDocuments from "../MyDocuments/DocumentLegalDocs";
import DocumentOthers from "../MyDocuments/DocumentOthers";
import DocumentReceipts from "../MyDocuments/DocumentReceipts";
import Employee from "../MyFarm/Employees";
import JoinFarm from "../MyFarm/JoinFarm";
import NewFarm from "../MyFarm/NewFarm";
import Pasture from "../MyFarm/Pastures";
import Stock from "../MyFarm/Stock";
import Task from "../MyTasks/Tasks";
import CompletedTask from "../MyTasks/TasksCompleted";
import NotCompletedTask from "../MyTasks/TasksNotCompleted";

function ContentComponent({ content, indexTab }) {
  return (
    <Flex mt="50" display="flex" flexDir="column" w="100%">
      <Heading fontSize="xx-large">{content.heading}</Heading>
      <Tabs isFitted colorScheme="green" defaultIndex={indexTab}>
        <TabList>
          {content.nagivate.map((item, index) => {
            return <Tab key={index}>{item}</Tab>;
          })}
        </TabList>
        <TabPanels>
          {content.nagivate.map((item, index) => {
            return (
              <React.Fragment key={index}>
                {item === "Stock" ? (
                  <TabPanel>
                    <Stock />
                  </TabPanel>
                ) : item === "Pasture Divisions" ? (
                  <TabPanel>
                    <Pasture />
                  </TabPanel>
                ) : item === "Employees" ? (
                  <TabPanel>
                    <Employee />
                  </TabPanel>
                ) : item === "Insemination Control" ? (
                  <TabPanel>
                    <Insemination />
                  </TabPanel>
                ) : item === "Vaccine Control" ? (
                  <TabPanel>
                    <Vaccination />
                  </TabPanel>
                ) : item === "All Tasks" ? (
                  <TabPanel>
                    <Task />
                  </TabPanel>
                ) : item === "Completed" ? (
                  <TabPanel>
                    <CompletedTask />
                  </TabPanel>
                ) : item === "Not Completed" ? (
                  <TabPanel>
                    <NotCompletedTask />
                  </TabPanel>
                ) : item === "Batchs" ? (
                  <TabPanel>
                    <BatchAndAnimal />
                  </TabPanel>
                ) : item === "New Farm" ? (
                  <TabPanel>
                    <NewFarm />
                  </TabPanel>
                ) : item === "Join Farm" ? (
                  <TabPanel>
                    <JoinFarm />
                  </TabPanel>
                ) : item === "Receipts" ? (
                  <TabPanel>
                    <DocumentReceipts />
                  </TabPanel>
                ) : item === "Bills" ? (
                  <TabPanel>
                    <DocumentBills />
                  </TabPanel>
                ) : item === "Invoices" ? (
                  <TabPanel>
                    <DocumentInvoices />
                  </TabPanel>
                ) : item === "Legal Documents" ? (
                  <TabPanel>
                    <DocumentLegalDocuments />
                  </TabPanel>
                ) : item === "Others" ? (
                  <TabPanel>
                    <DocumentOthers />
                  </TabPanel>
                ) : (
                  ""
                )}
              </React.Fragment>
            );
          })}
        </TabPanels>
      </Tabs>
    </Flex>
  );
}

export default ContentComponent;
