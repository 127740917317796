import {
  Box,
  Button,
  Collapse,
  Flex,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../firebase/firebase";
import ResultTable from "../Modals/ResultTable";
import OverallModal from "../Modals/FormModal";
import BatchDataCard from "./batchDataCard";
import { setCounterRedux } from "../../redux/slices/counterSlice";
import moment from "moment";
const currentDate = moment().format("YYYY-MM-DD");

function BatchAndAnimal() {
  const batch = {
    modalHeading: "New Batch",
    editHeading: "Edit Batch",
    modalLabel: [
      "Batch Number",
      "Animal Type",
      "Amount",
      "Batch DOB",
      "Pasture Division",
      "Breed",
      "Sex",
      "Description",
    ],
    modalPlaceholder: [
      "Batch Number",
      "Animal Type",
      "Amount",
      "Age",
      "Pasture Division",
      "Breed",
      "Sex",
      "Description",
    ],
    modalValue: [
      "batchNumber",
      "animalType",
      "numberOfAnimals",
      "batchAge",
      "pastureDivision",
      "breed",
      "sex",
      "description",
    ],
    type: ["number", "text", "number", "date", "text", "text", "text", "text"],
    batchPath: {
      batchNumber: "",
      animalType: "",
      numberOfAnimals: "",
      batchAge: currentDate,
      pastureDivision: "",
      breed: "",
      sex: "",
      description: "N/A",
    },
  };

  const animal = {
    modalHeading: " New Animal",
    editHeading: " Edit Animal",
    modalLabel: [
      "Tag Number",
      "Type",
      "Date of Birth",
      "Price",
      "Breed",
      "Sex",
      "Calf",
      "Mother's Tag",
      "Weight",
      "Weight Check",
      "Batch",
      "Status",
      "Description",
    ],
    modalPlaceholder: [
      "Tag Number",
      "Type",
      "Date of Birth",
      "Price",
      "Breed",
      "Sex",
      "Calf",
      "Mother's Tag",
      "Weight",
      "Weight Check",
      "Batch",
      "Status",
      "Description",
    ],
    modalValue: [
      "tagNumber",
      "type",
      "age",
      "price",
      "breed",
      "sex",
      "calf",
      "motherTag",
      "weight",
      "weightCheck",
      "batch",
      "animalStatus",
      "description",
    ],
    type: [
      "number",
      "text",
      "date",
      "number",
      "text",
      "text",
      "text",
      "number",
      "number",
      "date",
      "number",
      "text",
      "text",
    ],
    animalPath: {
      tagNumber: "",
      type: "",
      age: currentDate,
      price: "0",
      breed: "",
      sex: "",
      calf: "No",
      motherTag: "",
      weight: "0",
      weightCheck: currentDate,
      batch: "",
      animalStatus: "Alive",
      tries: "0",
      description: "N/A",
    },
  };

  const {
    isOpen: isOpenBatch,
    onOpen: onOpenBatch,
    onClose: onCloseBatch,
  } = useDisclosure();
  const { isOpen, onToggle } = useDisclosure();
  const {
    isOpen: isOpenAnimal,
    onOpen: onOpenAnimal,
    onClose: onCloseAnimal,
  } = useDisclosure();

  const [newDataBatch, setNewDataBatch] = useState(batch.batchPath);
  const [batchsData, setBatchsData] = useState([]);
  const [selectedBatch, setSelectedBatch] = useState(null);
  const { farmName } = useSelector((state) => state.farmNameSelected);
  const { counter } = useSelector((state) => state.counterSelected);
  const { userID } = useSelector((state) => state.userIDSelected);
  const { batchData } = useSelector((state) => state.batchDataSelected);
  const dispatch = useDispatch();
  const [newDataAnimal, setNewDataAnimal] = useState(animal.animalPath);
  const [animalsData, setAnimalsData] = useState([]);

  const toast = useToast();
  function showToast() {
    toast({
      title: "Create a Batch first!",
      status: "warning",
      duration: 5000,
      isClosable: true,
    });
  }

  useEffect(() => {
    async function fetchData() {
      if (farmName !== "") {
        const farmNameRef = doc(db, "farms", farmName);
        const docRef = collection(farmNameRef, "batchs");

        const docSnapBatch = await getDocs(docRef);
        const dataBatch = [];
        const addedBatchNumbers = new Set();

        docSnapBatch.forEach((doc) => {
          if (!addedBatchNumbers.has(doc.data().batchNumber)) {
            dataBatch.push({ id: doc.id, data: doc.data() });
            addedBatchNumbers.add(doc.data().batchNumber);
          }
        });
        setBatchsData(dataBatch);

        console.log("counter is", counter);
        loadAnimalsSingleBatch(selectedBatch);
      }
    }
    fetchData();
  }, [farmName, counter, userID]);

  const loadAnimalsSingleBatch = async (batch) => {
    const farmNameRef = doc(db, "farms", farmName);
    const animalRef = collection(farmNameRef, "animals");
    const batchAnimals = query(animalRef, where("batch", "==", batch));

    const docSnapAnimal = await getDocs(batchAnimals);
    const dataAnimal = [];
    docSnapAnimal.forEach((doc) => {
      dataAnimal.push({ id: doc.id, data: doc.data() });
    });
    setAnimalsData(dataAnimal);
  };

  const addBatch = async () => {
    const farmNameRef = doc(db, "farms", farmName);
    const docRef = collection(farmNameRef, "batchs");
    try {
      await addDoc(docRef, newDataBatch);
      dispatch(setCounterRedux(counter + 1));
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };
  const addAnimal = async () => {
    const farmNameRef = doc(db, "farms", farmName);
    const collectionRef = collection(farmNameRef, "animals");
    try {
      await addDoc(collectionRef, newDataAnimal);
      dispatch(setCounterRedux(counter + 1));
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  return (
    <>
      <Flex
        w="100%"
        flexWrap="wrap"
        flexDir="column"
        bgRepeat="no-repeat"
        bgSize="cover"
        bgColor="white"
      >
        <Flex
          flexWrap="wrap"
          flexDir="row"
          my="3"
          justifyContent="left"
          display="flex"
        >
          <Button
            width="fit-content"
            mx="1"
            my="1"
            color="white"
            bg="green.500"
            fontSize="13"
            fontWeight="bold"
            onClick={() => {
              if (batchData.length) {
                onOpenAnimal();
              } else {
                showToast();
              }
            }}
          >
            New Animal +
          </Button>
          <Button
            width="fit-content"
            mx="1"
            my="1"
            color="white"
            bg="green.500"
            fontSize="13"
            fontWeight="bold"
            onClick={() => {
              onOpenBatch();
            }}
          >
            New Batch +
          </Button>
          <Button
            data-testid="show-batch-button"
            color="white"
            onClick={onToggle}
            mx="1"
            my="1"
            fontSize="13"
            width="fit-content"
            bg="purple.500"
          >
            {isOpen ? "Hide Batchs" : "Show Batchs"}
          </Button>
        </Flex>
        <Flex
          w="100%"
          justifyContent="center"
          display="flex"
          flexDirection="column"
        >
          <Flex display="flex" justifyContent="center">
            <Collapse in={isOpen} animateOpacity>
              <Box
                w="fit-content"
                color="black"
                bg="white"
                rounded="md"
                shadow="md"
                my="10"
              >
                <ResultTable
                  w="100%"
                  schema={batch}
                  data={batchsData}
                  currentDoc={"batchs"}
                />
              </Box>
            </Collapse>
          </Flex>
          <Flex
            width="100%"
            justifyContent="center"
            overflowX="scroll"
            sx={{ whiteSpace: "nowrap" }}
          >
            {batchsData.map((item, index) => {
              return (
                <Box display="inline-block" key={index} mr={2}>
                  <BatchDataCard
                    data={item}
                    selectedBatch={selectedBatch}
                    setSelectedBatch={setSelectedBatch}
                    batch={loadAnimalsSingleBatch}
                  />
                </Box>
              );
            })}
          </Flex>
          <ResultTable
            w="100%"
            schema={animal}
            data={animalsData}
            currentDoc={"animals"}
            batchN={batchsData}
          />
        </Flex>
      </Flex>
      <OverallModal
        isOpen={isOpenBatch}
        onClose={onCloseBatch}
        data={batch}
        addNew={addBatch}
        newData={newDataBatch}
        setNew={setNewDataBatch}
      />
      <OverallModal
        setSelectedBatch={setSelectedBatch}
        batchN={batchsData}
        isOpen={isOpenAnimal}
        onClose={onCloseAnimal}
        data={animal}
        addNew={addAnimal}
        newData={newDataAnimal}
        setNew={setNewDataAnimal}
      />
    </>
  );
}

export default BatchAndAnimal;
