import { configureStore } from "@reduxjs/toolkit";
import batchDataSlice from "./slices/batchDataSlice";
import counterSlice from "./slices/counterSlice";

import farmNameSlice from "./slices/farmNameSlice";
import pastureDataSlice from "./slices/pastureDataSlice";
import userDataSlice from "./slices/userDataSlice";
import userIDSlice from "./slices/userIDSlice";
import userNameSlice from "./slices/userNameSlice";

export const store = configureStore({
  reducer: {
    farmNameSelected: farmNameSlice,
    userIDSelected: userIDSlice,
    userNameSelected: userNameSlice,
    userDataSelected: userDataSlice,
    batchDataSelected: batchDataSlice,
    counterSelected: counterSlice,
    pastureDataSelected: pastureDataSlice,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: [
          "userNameSelected/setUserNameRedux",
          "userIDSelected/setUserIDRedux",
          "userDataSelected/setUserDataRedux",
        ],
        // Ignore these field paths in all actions
        ignoredActionPaths: ["userDataSelected.userData"],

        // Ignore these paths in the state
        ignoredPaths: ["userDataSelected.userData"],
      },
    }),
});
