import React, { useEffect, useState } from "react";
import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import * as Moment from "moment";
import { extendMoment } from "moment-range";
import { useSelector } from "react-redux";

const moment = extendMoment(Moment);
moment.locale = "en";

function EmployeeInfoModal({
  isOpen,
  onClose,
  employeeName,
  employeeID,
  employeePosition,
}) {
  const [currentEmployeeData, setCurrentEmployeeData] = useState(null);
  const { counter } = useSelector((state) => state.counterSelected);

  useEffect(() => {
    if (isOpen) {
      async function fetchData() {
        const userNameRef = doc(db, "users", employeeID);
        const docSnap = await getDoc(userNameRef);
        setCurrentEmployeeData(docSnap.data());
      }
      fetchData();
    }
  }, [isOpen, , counter]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Employee: {employeeName}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex>
            <>
              {currentEmployeeData !== null ? (
                <Flex justify="center" direction="column">
                  <Flex m="2vh" direction row>
                    <Flex fontWeight="bold">First Name:</Flex>
                    <Flex mx="1vw">{currentEmployeeData.fName}</Flex>
                  </Flex>
                  <Flex m="2vh" direction row>
                    <Flex fontWeight="bold">Last Name::</Flex>
                    <Flex mx="1vw">{currentEmployeeData.lName}</Flex>
                  </Flex>
                  <Flex m="2vh" direction row>
                    <Flex fontWeight="bold">Date of Birth:</Flex>
                    <Flex mx="1vw">
                      {moment(currentEmployeeData.dob).format("DD/MM/YYYY")}
                    </Flex>
                  </Flex>
                  <Flex m="2vh" direction row>
                    <Flex fontWeight="bold">Gender:</Flex>
                    <Flex mx="1vw">{currentEmployeeData.gender}</Flex>
                  </Flex>
                  <Flex m="2vh" direction row>
                    <Flex fontWeight="bold">Phone Number:</Flex>
                    <Flex mx="1vw">{currentEmployeeData.phone}</Flex>
                  </Flex>
                  <Flex m="2vh" direction row>
                    <Flex fontWeight="bold">Email:</Flex>
                    <Flex mx="1vw">{currentEmployeeData.email}</Flex>
                  </Flex>
                  <Flex m="2vh" direction row>
                    <Flex fontWeight="bold">Position:</Flex>
                    <Flex mx="1vw">{employeePosition}</Flex>
                  </Flex>
                  <Flex m="2vh" direction row>
                    <Flex fontWeight="bold">Description: </Flex>
                    <Flex mx="1vw">{currentEmployeeData.description}</Flex>
                  </Flex>
                </Flex>
              ) : (
                <Flex justify="center">Loading...</Flex>
              )}
            </>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button color="white" bg="green.500" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default EmployeeInfoModal;
