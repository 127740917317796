import { createSlice } from "@reduxjs/toolkit";

export const farmNameSlice = createSlice({
  name: "farmNameSelected",
  initialState: {
    farmName: "",
  },
  reducers: {
    setFarmNameRedux: (state, action) => {
      state.farmName = action.payload;
    },
  },
});

export const { setFarmNameRedux } = farmNameSlice.actions;

export default farmNameSlice.reducer;
