import {
  ModalHeader,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalBody,
  ModalFooter,
  Button,
  Spacer,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  useToast,
} from "@chakra-ui/react";

const documentsFolders = [
  "Receipts",
  "Bills",
  "Invoices",
  "Legal Documents",
  "Others",
];

function DocumentModal({
  isOpen,
  onClose,
  setSelectedFile,
  selectedFile,
  uploadFile,
  fileInfo,
  setFileInfo,
  selectedFolder,
  setSelectedFolder,
}) {
  const toast = useToast();
  function showToastInput() {
    toast({
      title: "Insert Data in All Required Fields!",
      status: "warning",
      duration: 5000,
      isClosable: true,
    });
  }

  const checkValidInput = () => {
    console.log("folder", selectedFolder);
    console.log("file", selectedFile);
    console.log("fileInfo", fileInfo);
    if (
      selectedFolder === "" ||
      selectedFile === null ||
      fileInfo.documentName === "" ||
      fileInfo.documentDate === ""
    ) {
      showToastInput();
      return false;
    }
    return true;
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign="center">New Document</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl p="1vw" isRequired>
            <FormLabel fontWeight="bold" fontSize="sm">
              Select Document:
            </FormLabel>
            <Flex justify="center" direction="column">
              <input
                isRequired
                type="file"
                onChange={(e) => {
                  setSelectedFile(e.target.files[0]);
                  console.log("file is:", e.target.files[0]);
                }}
              />
            </Flex>
          </FormControl>
          <FormControl p="1vw" isRequired>
            <FormLabel fontWeight="bold" fontSize="sm">
              Document Name:
            </FormLabel>
            <Input
              type="text"
              placeholder="Add Document Name"
              value={
                fileInfo && fileInfo.documentName ? fileInfo.documentName : ""
              }
              onChange={(text) => {
                const copy = { ...fileInfo };
                copy.documentName = text.target.value;
                setFileInfo(copy);
                console.log("title is:", text.target.value);
              }}
            />
          </FormControl>
          <Flex>
            <FormControl p="1vw" isRequired>
              <FormLabel fontWeight="bold" fontSize="sm">
                Folder Name:
              </FormLabel>
              <Select
                placeholder="Select folder"
                variant="filled"
                onChange={(e) => {
                  let copy = { ...selectedFolder };
                  copy = e.target.value;
                  setSelectedFolder(copy);
                  console.log("folder name:", e.target.value);
                }}
              >
                {documentsFolders.map((item, index) => (
                  <option key={index}>{item}</option>
                ))}
              </Select>
            </FormControl>
          </Flex>
          <Flex>
            <FormControl p="1vw" isRequired>
              <FormLabel fontWeight="bold" fontSize="sm">
                Date:
              </FormLabel>
              <Input
                type="date"
                placeholder="Select date"
                value={
                  fileInfo && fileInfo.documentDate ? fileInfo.documentDate : ""
                }
                onChange={(text) => {
                  const copy = { ...fileInfo };
                  copy.documentDate = text.target.value;
                  setFileInfo(copy);
                  console.log("document date:", text.target.value);
                }}
              />
            </FormControl>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button color="white" bg="green.500" onClick={onClose}>
            Cancel
          </Button>
          <Spacer />
          <Button
            color="white"
            bg="green.500"
            onClick={() => {
              if (checkValidInput()) {
                uploadFile(selectedFile, selectedFolder);
                onClose();
              }
            }}
          >
            Add
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default DocumentModal;
