import { createSlice } from "@reduxjs/toolkit";

export const batchDataSlice = createSlice({
  name: "batchDataSelected",
  initialState: {
    batchData: "",
  },
  reducers: {
    setBatchDataRedux: (state, action) => {
      state.batchData = action.payload;
    },
  },
});

export const { setBatchDataRedux } = batchDataSlice.actions;

export default batchDataSlice.reducer;
