import { Box, Button, Flex, Text, useDisclosure } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../firebase/firebase";
import moment from "moment";
import { setCounterRedux } from "../../redux/slices/counterSlice";
import OverallModal from "../Modals/FormModal";
import ResultTable from "../Modals/ResultTable";
// const currentDate = moment().format("YYYY-MM-DD");

const currentDate = moment("2023-05-01").format("YYYY-MM-DD");

const task = {
  modalHeading: " New Task",
  editHeading: " Edit Task",
  modalLabel: [
    "Date",
    "Task due Date",
    "Work to do",
    "Priority",
    "Status",
    "Person in Charge",
    "Comment",
  ],
  modalPlaceholder: [
    "Date",
    "Task due Date",
    "Work to do",
    "Priority",
    "Status",
    "Person in Charge",
    "Comment",
  ],
  modalValue: [
    "taskDate",
    "taskDueDate",
    "workToDo",
    "priority",
    "status",
    "personInCharge",
    "comment",
  ],
  type: ["date", "date", "text", "text", "text", "text", "text"],
  taskPath: {
    taskDate: currentDate,
    taskDueDate: moment(currentDate).add(1, "days").format("YYYY-MM-DD"),
    workToDo: "",
    priority: "",
    status: "Not Completed",
    personInCharge: "",
    comment: "N/A",
  },
};

function CompletedTask() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [newData, setNewData] = useState(task.taskPath);
  const [taskData, setTaskData] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [currentUserEmployeeData, setCurrentUserEmployeeData] = useState({});
  const { farmName } = useSelector((state) => state.farmNameSelected);
  const { userID } = useSelector((state) => state.userIDSelected);
  const { counter } = useSelector((state) => state.counterSelected);
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchData() {
      if (farmName !== "") {
        const farmNameRef = doc(db, "farms", farmName);
        const docRef = collection(farmNameRef, "tasks");

        const docRefEmployees = collection(farmNameRef, "employees");
        const docRefCurrentEmployee = doc(
          collection(farmNameRef, "employees"),
          userID
        );

        const completedQuery = query(
          docRef,
          where("status", "==", "Completed"),
          orderBy("taskDueDate", "asc")
        );
        const docSnap = await getDocs(completedQuery);
        const data = [];
        docSnap.forEach((doc) => {
          data.push({ id: doc.id, data: doc.data() });
        });
        setTaskData(data);

        const employeeData = [];
        const docSnapEmployees = await getDocs(docRefEmployees);
        docSnapEmployees.forEach((doc) => {
          employeeData.push({ id: doc.id, data: doc.data() });
        });
        setEmployeeData(employeeData);

        const docSnapCurrentEmployee = await getDoc(docRefCurrentEmployee);
        setCurrentUserEmployeeData(docSnapCurrentEmployee.data());
      }
    }
    fetchData();
  }, [farmName, userID, counter]);

  const addSingleTask = async () => {
    const farmNameRef = doc(db, "farms", farmName);
    const docRef = collection(farmNameRef, "tasks");
    try {
      await addDoc(docRef, newData);
      dispatch(setCounterRedux(counter + 1));
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  return (
    <>
      <Flex
        w="100%"
        flexWrap="wrap"
        flexDir="column"
        bgRepeat="no-repeat"
        bgSize="cover"
        bgColor="white"
      >
        <Flex
          flexWrap="wrap"
          flexDir="row"
          my="3"
          justifyContent="left"
          display="flex"
        >
          <Button
            width="fit-content"
            mx="1"
            my="1"
            color="white"
            bg="green.500"
            fontSize="13"
            fontWeight="bold"
            onClick={() => {
              onOpen();
            }}
          >
            New Task +
          </Button>
        </Flex>
        <Flex display="flex" flexWrap="wrap">
          <Flex display="flex" flexDirection="column" flexWrap="wrap">
            <Text>Priority:</Text>
            <Flex>
              <Flex>
                <Box w="5" h="5" borderRadius="5" bg="purple.400" mx="1" />
                <Text>High</Text>
              </Flex>
              <Flex>
                <Box w="5" h="5" borderRadius="5" bg="purple.200" mx="1" />
                <Text>Medium</Text>
              </Flex>
              <Flex>
                <Box w="5" h="5" borderRadius="5" bg="purple.100" mx="1" />
                <Text>Low</Text>
              </Flex>
            </Flex>
          </Flex>
          <Flex display="flex" flexDirection="column" ml="10" flexWrap="wrap">
            <Text>Status:</Text>
            <Flex>
              <Flex>
                <Box w="5" h="5" borderRadius="5" bg="green.200" mx="1" />
                <Text>Completed</Text>
              </Flex>
              <Flex>
                <Box
                  w="5"
                  h="5"
                  borderRadius="5"
                  bg="white"
                  mx="1"
                  border="solid 1px black"
                />
                <Text>Not Completed</Text>
              </Flex>
              <Flex>
                <Box w="5" h="5" borderRadius="5" bg="red.400" mx="1" />
                <Text>Overdue</Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          w="100%"
          justifyContent="center"
          display="flex"
          flexDirection="column"
        >
          <ResultTable
            w="100%"
            schema={task}
            data={taskData}
            docData={currentDate}
            currentUser={currentUserEmployeeData}
            currentDoc={"tasks"}
            employeeData={employeeData}
          />
        </Flex>
      </Flex>
      <OverallModal
        isOpen={isOpen}
        onClose={onClose}
        data={task}
        addNew={addSingleTask}
        employeeData={employeeData}
        newData={newData}
        setNew={setNewData}
      />
    </>
  );
}

export default CompletedTask;
