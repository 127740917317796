import {
  Button,
  Flex,
  Img,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdOutlineWarehouse } from "react-icons/md";
import { GiCow } from "react-icons/gi";
import { IoPerson } from "react-icons/io5";
import { AiOutlineHome } from "react-icons/ai";
import { GrEdit } from "react-icons/gr";
import { BiTask } from "react-icons/bi";
import { HiOutlineDocumentDuplicate } from "react-icons/hi";
import { useNavigate } from "react-router";
import {
  getAuth,
  onAuthStateChanged,
  signInWithPopup,
  signOut,
} from "@firebase/auth";
import { setUserIDRedux } from "../../redux/slices/userIDSlice";
import { setUserNameRedux } from "../../redux/slices/userNameSlice";
import { db, provider } from "../../firebase/firebase";
import { setUserDataRedux } from "../../redux/slices/userDataSlice";

import cow from "../../Assets/images/logofazenda.jpeg";
import { collection, doc, getDoc } from "firebase/firestore";
import { setCounterRedux } from "../../redux/slices/counterSlice";
import { setFarmNameRedux } from "../../redux/slices/farmNameSlice";
import Profile from "../MyProfile/Profile";

function SideMenu({ selectPage }) {
  const {
    isOpen: isOpenSideMenu,
    onOpen: onOpenSideMenu,
    onClose: onCloseSideMenu,
  } = useDisclosure();
  const {
    isOpen: isOpenFarmInfo,
    onOpen: onOpenFarmInfo,
    onClose: onCloseFarmInfo,
  } = useDisclosure();
  const {
    isOpen: isOpenProfile,
    onOpen: onOpenProfile,
    onClose: onCloseProfile,
  } = useDisclosure();

  const { farmName } = useSelector((state) => state.farmNameSelected);
  const { userID } = useSelector((state) => state.userIDSelected);
  const { userData } = useSelector((state) => state.userDataSelected);
  const { counter } = useSelector((state) => state.counterSelected);
  const [selectedPage, setSelectedPage] = useState("home");
  const [farmData, setFarmData] = useState();
  const [currentUserFarm, setCurrentUserFarm] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const dispatch = useDispatch();
  const auth = getAuth();
  const toast = useToast();
  useEffect(() => {
    async function fetchData() {
      if (farmName !== "") {
        const farmNameRef = doc(db, "farms", farmName);
        const docRefCurrentEmployee = doc(
          collection(farmNameRef, "employees"),
          userID
        );
        const docSnap = await getDoc(farmNameRef);
        setFarmData(docSnap.data());

        const docSnapCurrentEmployee = await getDoc(docRefCurrentEmployee);

        setCurrentUserFarm(docSnapCurrentEmployee.data());
      } else {
        setFarmData("");
        setCurrentUserFarm("");
      }
    }
    fetchData();
    onOpenSideMenu();
    onAuthStateChanged(auth, (user) => {
      if (user != null) {
        setCurrentUser(user);
        dispatch(setUserIDRedux(user.uid));
        dispatch(setUserNameRedux(user.displayName));
        dispatch(setUserDataRedux(user));
      } else {
        setCurrentUser(null);
      }
    });
  }, [farmName, userID, counter]);

  const signUserOut = () => {
    signOut(auth)
      .then(() => {
        console.log("User signed out successfully");
        dispatch(setUserIDRedux(""));
        dispatch(setFarmNameRedux(""));
        setPageValues("home", "home");
        dispatch(setCounterRedux(counter + 1));
      })
      .catch((error) => {
        console.error("Error signing out:", error);
      });
  };

  const signInUser = () => {
    signInWithPopup(auth, provider).catch((error) => {
      console.log(error);

      dispatch(setCounterRedux(counter - 1));
    });
  };

  const setPageValues = (any, any2) => {
    setSelectedPage(any);
    selectPage(any2);
  };

  function showToast() {
    toast({
      title: "Select a farm first!",
      status: "error",
      duration: 5000,
      isClosable: true,
    });
  }

  function showToastSignIn() {
    toast({
      title: "Sign In First!",
      status: "error",
      duration: 5000,
      isClosable: true,
    });
  }

  return (
    <>
      <Flex
        bg="gray.100"
        h="100%"
        w="100%"
        display="flex"
        flexDir="column"
        align="center"
        justify="space-between"
      >
        <Flex
          mt="5"
          boxShadow="0 0 10px rgba(0, 0, 0, 0.1)"
          bg="white"
          display="flex"
          px="5"
          pt="2"
          width="fit-content"
          height="fit-content"
          flexDir="column"
          borderRadius="2xl"
        >
          <Flex marginLeft="auto">
            <Menu>
              {({ isOpen }) => (
                <>
                  <Flex fontSize="xl">
                    <MenuButton>{isOpen ? <GrEdit /> : <GrEdit />}</MenuButton>
                  </Flex>
                  <MenuList>
                    {currentUserFarm === "" ? (
                      <MenuItem isDisabled>SignIn First</MenuItem>
                    ) : farmName === "" ? (
                      <MenuItem isDisabled>Select a farm profile</MenuItem>
                    ) : currentUserFarm !== "" && farmName !== "" ? (
                      currentUserFarm.position === "manager" ? (
                        <Flex display="flex" flexDir="column">
                          <MenuItem isDisabled>
                            Farm ID: {farmData.farmID}
                          </MenuItem>
                          <MenuItem isDisabled>
                            Manager Password: {farmData.managerPassword}
                          </MenuItem>
                          <MenuItem isDisabled>
                            Employee Password: {farmData.employeePassword}
                          </MenuItem>
                        </Flex>
                      ) : (
                        <Flex display="flex" flexDir="column">
                          <MenuItem isDisabled>
                            Farm ID: {farmData.farmID}
                          </MenuItem>

                          <MenuItem isDisabled>
                            Employee Password: {farmData.employeePassword}
                          </MenuItem>
                        </Flex>
                      )
                    ) : (
                      ""
                    )}
                  </MenuList>
                </>
              )}
            </Menu>
          </Flex>
          <Img mt="2" boxSize="100px" src={cow} borderRadius="80" />
          <Flex
            mt="1"
            display="flex"
            align="center"
            justify="center"
            fontStyle="bold"
            maxW="20"
            ml="2"
            flexWrap="wrap"
            fontSize="lg"
            textAlign="center"
          >
            {userID !== "" ? farmName : ""}
          </Flex>
        </Flex>
        <Flex
          mt="30"
          fontSize="lg"
          display="flex"
          flexDir="column"
          justifyContent="center"
        >
          <Flex
            my="1"
            textColor={selectedPage === "home" ? "green.500" : "black"}
            onClick={() => {
              setPageValues("home", "home");
            }}
          >
            <Flex mt="1">
              <AiOutlineHome />
            </Flex>
            <Text ml="2">Home</Text>
          </Flex>
          <Flex
            my="1"
            textColor={selectedPage === "farm" ? "green.500" : "black"}
            onClick={() => {
              if (userID !== "") {
                if (farmName !== "") {
                  setPageValues("farm", "myFarm");
                } else {
                  showToast();
                }
              } else {
                showToastSignIn();
              }
            }}
          >
            <Flex mt="1">
              <MdOutlineWarehouse />
            </Flex>
            <Tooltip
              hasArrow
              closeDelay={500}
              label="Employees, Pasture Divisions, Stock Control"
              placement="left-start"
              bg="green.500"
              color="white"
              fontSize="15"
              align="center"
              borderRadius="15"
            >
              <Text ml="2">My Farm</Text>
            </Tooltip>
          </Flex>
          <Flex
            my="1"
            textColor={selectedPage === "task" ? "green.500" : "black"}
            onClick={() => {
              if (userID !== "") {
                if (farmName !== "") {
                  setPageValues("task", "myTasks");
                } else {
                  showToast();
                }
              } else {
                showToastSignIn();
              }
            }}
          >
            <Flex mt="1">
              <BiTask />
            </Flex>
            <Tooltip
              hasArrow
              closeDelay={500}
              label="All Tasks, Completed Tasks, Incomplete Tasks"
              placement="left-start"
              bg="green.500"
              color="white"
              fontSize="15"
              align="center"
              borderRadius="15"
            >
              <Text ml="2">My Tasks</Text>
            </Tooltip>
          </Flex>
          <Flex
            my="1"
            textColor={selectedPage === "animals" ? "green.500" : "black"}
            onClick={() => {
              if (userID !== "") {
                if (farmName !== "") {
                  setPageValues("animals", "myAnimals");
                } else {
                  showToast();
                }
              } else {
                showToastSignIn();
              }
            }}
          >
            <Flex mt="1">
              <GiCow />
            </Flex>
            <Tooltip
              hasArrow
              closeDelay={500}
              label="Batch Control, Insemination Control, Vaccination Control"
              placement="left-start"
              bg="green.500"
              color="white"
              fontSize="15"
              align="center"
              borderRadius="15"
            >
              <Text ml="2">My Animals</Text>
            </Tooltip>
          </Flex>
          <Flex
            textColor={selectedPage === "documents" ? "green.500" : "black"}
            my="1"
            onClick={() => {
              if (userID !== "") {
                if (farmName !== "") {
                  setPageValues("documents", "myDocuments");
                } else {
                  showToast();
                }
              } else {
                showToastSignIn();
              }
            }}
          >
            <Flex mt="1">
              <HiOutlineDocumentDuplicate />
            </Flex>
            <Tooltip
              hasArrow
              closeDelay={500}
              label="Receipts, Bills, Invoices, Legal Documents, Others"
              placement="left-start"
              bg="green.500"
              color="white"
              fontSize="15"
              align="center"
              borderRadius="15"
            >
              <Text ml="2">My Documents</Text>
            </Tooltip>
          </Flex>
        </Flex>
        <Flex>
          <Flex
            fontSize="xl"
            display="flex"
            flexDir="column"
            textStyle="bold"
            mb="5"
          >
            <Flex
              onClick={() => {
                userID !== "" ? onOpenProfile() : showToastSignIn();
              }}
            >
              My Profile
            </Flex>
            <Flex onClick={() => {}}>Help</Flex>
            {currentUser !== null ? (
              <Button
                leftIcon={<IoPerson />}
                color="white"
                w="fit-content"
                h="fit-content"
                p="2"
                bgColor="green.500"
                onClick={() => {
                  signUserOut();
                }}
              >
                Sign out
              </Button>
            ) : (
              <Button
                leftIcon={<IoPerson />}
                color="white"
                w="fit-content"
                h="fit-content"
                p="2"
                bgColor="green.500"
                onClick={signInUser}
              >
                Sign In
              </Button>
            )}
          </Flex>
        </Flex>
      </Flex>
      <Profile isOpen={isOpenProfile} onClose={onCloseProfile} />
    </>
  );
}

export default SideMenu;
