import {
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { collection, doc, getDocs } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import EmployeeInfoModal from "../MyProfile/EmployeeInfoModal";

function Employee() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [employeesData, setEmployeesData] = useState([]);
  const [currentEmployeeName, setCurrentEmployeeName] = useState("");
  const [currentEmployeeID, setCurrentEmployeeID] = useState("");
  const [currentUserPostion, setCurrentUserPostion] = useState("");
  const { farmName } = useSelector((state) => state.farmNameSelected);
  const { counter } = useSelector((state) => state.counterSelected);
  const { userID } = useSelector((state) => state.userIDSelected);

  useEffect(() => {
    async function fetchData() {
      if (farmName !== "") {
        const farmNameRef = doc(db, "farms", farmName);
        const docRef = collection(farmNameRef, "employees");
        const docSnap = await getDocs(docRef);
        const data = [];
        docSnap.forEach((doc) => {
          data.push({ id: doc.id, data: doc.data() });
        });
        setEmployeesData(data);
      }
    }
    fetchData();
  }, [farmName, userID, counter]);

  return (
    <>
      <Flex
        w="100%"
        flexWrap="wrap"
        flexDir="column"
        bgRepeat="no-repeat"
        bgSize="cover"
        bgColor="white"
      >
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>Position</Th>
              </Tr>
            </Thead>
            <Tbody>
              {employeesData.map((item, index) => {
                return (
                  <Tr
                    key={index}
                    onClick={() => {
                      onOpen();
                      setCurrentEmployeeID(item.id);
                      setCurrentEmployeeName(item.data.userName);
                      setCurrentUserPostion(item.data.position);
                    }}
                  >
                    <Td>{item.data.userName}</Td>
                    <Td>{item.data.position}</Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      </Flex>
      <EmployeeInfoModal
        isOpen={isOpen}
        onClose={onClose}
        employeeName={currentEmployeeName}
        employeeID={currentEmployeeID}
        employeePosition={currentUserPostion}
      />
    </>
  );
}

export default Employee;
