import {
  ModalHeader,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalBody,
  ModalFooter,
  Button,
  Spacer,
  Flex,
  FormControl,
  FormLabel,
  Select,
  RadioGroup,
  HStack,
  Radio,
  useToast,
} from "@chakra-ui/react";
import FormTextInput from "../textInput";

import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { setCounterRedux } from "../../redux/slices/counterSlice";

const typeOfAnimal = [
  "Cow",
  "Bull",
  "Calf",
  "Cow & Calf",
  "Pregnant Cow",
  "Cow in Insemination Protocol",
  "Milk Cow",
];
const animalBreeds = [
  "Angus",
  "Hereford",
  "Shorthorn",
  "Simmental",
  "Nelore",
  "Jersey",
  "Holstein",
  "Charolais",
  "Limousin",
  "Ayrshire",
  "Guernsey",
  "Brown Swiss",
  "Dexter",
  "Zebu",
  "Other",
];
const animalStatus = ["Alive", "Dead", "Sold"];
const animalSex = ["Female", "Male", "Male & Female"];
const yesNo = ["Yes", "No"];
const quantityType = [
  "Kilograms",
  "Grams",
  "Ounces",
  "Pounds",
  "Liters",
  "Milliliters",
  "Gallons",
];
const vaccineType = ["Brucellosis", "Rabies", "Aftovaxpur"];
const sizeType = ["Acres", "Hectares", "Square Feet", "Square Meters"];
const taskPriority = ["High", "Medium", "Low"];

function EditModal({
  isOpen,
  onClose,
  schema,
  currentUser,
  saveData,
  data,
  setNew,
  deleteData,
  employeeData,
}) {
  const { batchData } = useSelector((state) => state.batchDataSelected);
  const { pastureData } = useSelector((state) => state.pastureDataSelected);
  const toast = useToast();

  function showToastInput() {
    toast({
      title: "Check All Required Fields!",
      status: "warning",
      duration: 5000,
      isClosable: true,
    });
  }

  const checkValidInput = () => {
    if (
      data.type === "" ||
      data.batchNumber === "" ||
      data.animalType === "" ||
      data.numberOfAnimals === "" ||
      data.batchAge === "" ||
      data.pastureDivision === "" ||
      data.age === "" ||
      data.price === "" ||
      data.breed === "" ||
      data.sex === "" ||
      data.calf === "" ||
      data.motherTag === "" ||
      data.weight === "" ||
      data.weightCheck === "" ||
      data.batch === "" ||
      data.animalStatus === "" ||
      data.tries === "" ||
      data.name === "" ||
      data.size === "" ||
      data.sizeType === "" ||
      data.capacity === "" ||
      data.fName === "" ||
      data.lName === "" ||
      data.dob === "" ||
      data.gender === "" ||
      data.phone === "" ||
      data.email === "" ||
      data.item === "" ||
      data.quantityType === "" ||
      data.quantity === "" ||
      data.reorderLevel === "" ||
      data.checkedDate === "" ||
      data.taskDate === "" ||
      data.taskDueDate === "" ||
      data.workToDo === "" ||
      data.priority === "" ||
      data.status === "" ||
      data.personInCharge === "" ||
      data.comment === "" ||
      data.batch === "" ||
      data.vaccineName === "" ||
      data.vaccineBatch === "" ||
      data.animalAge === "" ||
      data.controlSex === "" ||
      data.applicationPeriod === "" ||
      data.animalAge === "" ||
      data.vaccineSex === "" ||
      data.description === ""
    ) {
      showToastInput();
      return false;
    }
    return true;
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign="center">{schema.editHeading}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex justify="center" direction="column">
            <>
              {schema.modalLabel.map((item, index) => {
                return schema.modalValue[index] === "d7" ||
                  schema.modalValue[index] === "d9" ||
                  schema.modalValue[index] === "d11" ||
                  schema.modalValue[index] === "checkPregnance" ? (
                  <Flex key={index}>
                    <FormTextInput
                      status={true}
                      type={schema.type[index]}
                      label={item}
                      placeholder={schema.modalPlaceholder[index]}
                      value={
                        data && data[schema.modalValue[index]]
                          ? data[schema.modalValue[index]]
                          : ""
                      }
                      onChange={(text) => {
                        const copy = { ...data };
                        copy[schema.modalValue[index]] = text;

                        setNew(copy);
                      }}
                    />
                  </Flex>
                ) : schema.modalValue[index] === "batchAge" ? (
                  <Flex key={index}>
                    <FormTextInput
                      status={false}
                      type={schema.type[index]}
                      label={item}
                      placeholder={schema.modalPlaceholder[index]}
                      value={
                        data && data[schema.modalValue[index]]
                          ? data[schema.modalValue[index]]
                          : ""
                      }
                      onChange={(text) => {
                        const copy = { ...data };
                        copy[schema.modalValue[index]] = text;
                        setNew(copy);
                      }}
                    />
                  </Flex>
                ) : schema.modalValue[index] === "priority" ? (
                  <Flex>
                    <FormControl p="1vw" isRequired>
                      <FormLabel fontWeight="bold" fontSize="sm">
                        Task Priority
                      </FormLabel>
                      <Select
                        placeholder={
                          data[schema.modalValue[index]] === ""
                            ? "Priority"
                            : ""
                        }
                        value={data[schema.modalValue[index]]}
                        variant="filled"
                        onChange={(e) => {
                          const copy = { ...data };
                          copy[schema.modalValue[index]] = e.target.value;
                          setNew(copy);
                        }}
                      >
                        <>
                          {taskPriority.map((item, index) => (
                            <option key={index}>{item}</option>
                          ))}
                        </>
                      </Select>
                    </FormControl>
                  </Flex>
                ) : schema.modalValue[index] === "batch" ? (
                  <Flex>
                    <FormControl p="1vw" isRequired>
                      <FormLabel fontWeight="bold" fontSize="sm">
                        Batch
                      </FormLabel>
                      <Select
                        placeholder="Batch Number"
                        variant="filled"
                        onChange={(e) => {
                          const copy = { ...data };
                          copy[schema.modalValue[index]] = e.target.value;
                          setNew(copy);
                        }}
                      >
                        <>
                          {batchData.map((item, index) => (
                            <option key={index}>{item.data.batchNumber}</option>
                          ))}
                        </>
                      </Select>
                    </FormControl>
                  </Flex>
                ) : schema.modalValue[index] === "pastureDivision" ? (
                  <Flex>
                    <FormControl p="1vw" isRequired>
                      <FormLabel fontWeight="bold" fontSize="sm">
                        Pasture Division
                      </FormLabel>
                      <Select
                        placeholder={data[schema.modalValue[index]]}
                        variant="filled"
                        onChange={(e) => {
                          const copy = { ...data };
                          copy[schema.modalValue[index]] = e.target.value;
                          setNew(copy);
                        }}
                      >
                        <>
                          {pastureData !== undefined
                            ? pastureData.map((item, index) => (
                                <option key={index}>{item}</option>
                              ))
                            : ""}
                        </>
                      </Select>
                    </FormControl>
                  </Flex>
                ) : schema.modalValue[index] === "personInCharge" ? (
                  <Flex>
                    {currentUser.position === "manager" ? (
                      <FormControl p="1vw" isDisabled={false} isRequired>
                        <FormLabel fontWeight="bold" fontSize="sm">
                          Employee in charge
                        </FormLabel>
                        <Select
                          placeholder={data[schema.modalValue[index]]}
                          variant="filled"
                          onChange={(e) => {
                            const copy = { ...data };
                            copy[schema.modalValue[index]] = e.target.value;
                            setNew(copy);
                          }}
                        >
                          <>
                            {employeeData.map((item, index) => (
                              <option key={index}>{item.data.userName}</option>
                            ))}
                          </>
                        </Select>
                      </FormControl>
                    ) : (
                      <FormControl p="1vw" isDisabled={true} isRequired>
                        <FormLabel fontWeight="bold" fontSize="sm">
                          Employee in charge
                        </FormLabel>
                        <Select
                          placeholder={data[schema.modalValue[index]]}
                          variant="filled"
                          onChange={(e) => {
                            const copy = { ...data };
                            copy[schema.modalValue[index]] = e.target.value;
                            setNew(copy);
                          }}
                        >
                          <>
                            {employeeData.map((item, index) => (
                              <option key={index}>{item.data.userName}</option>
                            ))}
                          </>
                        </Select>
                      </FormControl>
                    )}
                  </Flex>
                ) : schema.modalValue[index] === "animalStatus" ? (
                  <Flex>
                    <FormControl p="1vw" isDisabled={false} isRequired>
                      <FormLabel fontWeight="bold" fontSize="sm">
                        Animal Status
                      </FormLabel>
                      <Select
                        placeholder={data[schema.modalValue[index]]}
                        variant="filled"
                        onChange={(e) => {
                          const copy = { ...data };
                          copy[schema.modalValue[index]] = e.target.value;
                          setNew(copy);
                        }}
                      >
                        <>
                          {animalStatus.map((item, index) => (
                            <option key={index}>{item}</option>
                          ))}
                        </>
                      </Select>
                    </FormControl>
                  </Flex>
                ) : schema.modalValue[index] === "vaccineName" ? (
                  <Flex>
                    <FormControl p="1vw" isDisabled={false} isRequired>
                      <FormLabel fontWeight="bold" fontSize="sm">
                        Vaccine Name
                      </FormLabel>
                      <Select
                        placeholder={data[schema.modalValue[index]]}
                        variant="filled"
                        onChange={(e) => {
                          const copy = { ...data };
                          copy[schema.modalValue[index]] = e.target.value;
                          setNew(copy);
                        }}
                      >
                        <>
                          {vaccineType.map((item, index) => (
                            <option key={index}>{item}</option>
                          ))}
                        </>
                      </Select>
                    </FormControl>
                  </Flex>
                ) : schema.modalValue[index] === "sizeType" ? (
                  <Flex>
                    <FormControl p="1vw" isDisabled={false} isRequired>
                      <FormLabel fontWeight="bold" fontSize="sm">
                        Size Type
                      </FormLabel>
                      <Select
                        placeholder={data[schema.modalValue[index]]}
                        variant="filled"
                        onChange={(e) => {
                          const copy = { ...data };
                          copy[schema.modalValue[index]] = e.target.value;
                          setNew(copy);
                        }}
                      >
                        <>
                          {sizeType.map((item, index) => (
                            <option key={index}>{item}</option>
                          ))}
                        </>
                      </Select>
                    </FormControl>
                  </Flex>
                ) : schema.modalValue[index] === "calf" ? (
                  <Flex>
                    <FormControl p="1vw" isDisabled={false} isRequired>
                      <FormLabel fontWeight="bold" fontSize="sm">
                        Calf
                      </FormLabel>
                      <Select
                        placeholder={data[schema.modalValue[index]]}
                        variant="filled"
                        onChange={(e) => {
                          const copy = { ...data };
                          copy[schema.modalValue[index]] = e.target.value;
                          setNew(copy);
                        }}
                      >
                        <>
                          {yesNo.map((item, index) => (
                            <option key={index}>{item}</option>
                          ))}
                        </>
                      </Select>
                    </FormControl>
                  </Flex>
                ) : schema.modalValue[index] === "quantityType" ? (
                  <Flex>
                    <FormControl p="1vw" isDisabled={false} isRequired>
                      <FormLabel fontWeight="bold" fontSize="sm">
                        Measure Type
                      </FormLabel>
                      <Select
                        placeholder={data[schema.modalValue[index]]}
                        variant="filled"
                        onChange={(e) => {
                          const copy = { ...data };
                          copy[schema.modalValue[index]] = e.target.value;
                          setNew(copy);
                        }}
                      >
                        <>
                          {quantityType.map((item, index) => (
                            <option key={index}>{item}</option>
                          ))}
                        </>
                      </Select>
                    </FormControl>
                  </Flex>
                ) : schema.modalValue[index] === "sex" ||
                  schema.modalValue[index] === "controlSex" ||
                  schema.modalValue[index] === "vaccineSex" ? (
                  <Flex>
                    <FormControl p="1vw" isRequired>
                      <FormLabel fontWeight="bold" fontSize="sm">
                        Sex
                      </FormLabel>
                      <Select
                        placeholder={data[schema.modalValue[index]]}
                        variant="filled"
                        onChange={(e) => {
                          const copy = { ...data };
                          copy[schema.modalValue[index]] = e.target.value;
                          setNew(copy);
                        }}
                      >
                        <>
                          {animalSex.map((item, index) => (
                            <option key={index}>{item}</option>
                          ))}
                        </>
                      </Select>
                    </FormControl>
                  </Flex>
                ) : schema.modalValue[index] === "breed" ? (
                  <Flex>
                    <FormControl p="1vw" isRequired>
                      <FormLabel fontWeight="bold" fontSize="sm">
                        Animal Breed
                      </FormLabel>
                      <Select
                        placeholder={data[schema.modalValue[index]]}
                        variant="filled"
                        onChange={(e) => {
                          const copy = { ...data };
                          copy[schema.modalValue[index]] = e.target.value;
                          setNew(copy);
                        }}
                      >
                        <>
                          {animalBreeds.map((item, index) => (
                            <option key={index}>{item}</option>
                          ))}
                        </>
                      </Select>
                    </FormControl>
                  </Flex>
                ) : schema.modalValue[index] === "type" ||
                  schema.modalValue[index] === "animalType" ? (
                  <Flex>
                    <FormControl p="1vw" isRequired>
                      <FormLabel fontWeight="bold" fontSize="sm">
                        Type
                      </FormLabel>
                      <Select
                        placeholder={data[schema.modalValue[index]]}
                        variant="filled"
                        onChange={(e) => {
                          const copy = { ...data };
                          copy[schema.modalValue[index]] = e.target.value;
                          setNew(copy);
                        }}
                      >
                        <>
                          {typeOfAnimal.map((item, index) => (
                            <option key={index}>{item}</option>
                          ))}
                        </>
                      </Select>
                    </FormControl>
                  </Flex>
                ) : schema.modalValue[index] === "status" ? (
                  <Flex>
                    {data.personInCharge === currentUser.userName ||
                    currentUser.positition === "manager" ? (
                      <FormControl p="1vw" isReadOnly={false} isRequired>
                        <FormLabel fontWeight="bold" fontSize="sm">
                          Task Status
                        </FormLabel>
                        <RadioGroup
                          defaultValue={data[schema.modalValue[index]]}
                        >
                          <HStack spacing="24px">
                            <Radio
                              value="Completed"
                              onChange={(e) => {
                                const copy = { ...data };
                                copy[schema.modalValue[index]] = e.target.value;
                                setNew(copy);
                              }}
                            >
                              Completed
                            </Radio>
                            <Radio
                              value="Not Completed"
                              onChange={(e) => {
                                const copy = { ...data };
                                copy[schema.modalValue[index]] = e.target.value;
                                setNew(copy);
                              }}
                            >
                              Not Completed
                            </Radio>
                          </HStack>
                        </RadioGroup>
                      </FormControl>
                    ) : (
                      <FormControl p="1vw" isReadOnly={true} isRequired>
                        <FormLabel fontWeight="bold" fontSize="sm">
                          Task Status
                        </FormLabel>
                        <RadioGroup
                          defaultValue={data[schema.modalValue[index]]}
                        >
                          <HStack spacing="24px">
                            <Radio
                              value="Completed"
                              onChange={(e) => {
                                const copy = { ...data };
                                copy[schema.modalValue[index]] = e.target.value;
                                setNew(copy);
                              }}
                            >
                              Completed
                            </Radio>
                            <Radio
                              value="Not Completed"
                              onChange={(e) => {
                                const copy = { ...data };
                                copy[schema.modalValue[index]] = e.target.value;
                                setNew(copy);
                              }}
                            >
                              Not Completed
                            </Radio>
                          </HStack>
                        </RadioGroup>
                      </FormControl>
                    )}
                  </Flex>
                ) : schema.modalValue[index] === "d0" ? (
                  <Flex key={index}>
                    <FormTextInput
                      status={false}
                      type={schema.type[index]}
                      label={item}
                      placeholder={schema.modalPlaceholder[index]}
                      value={
                        data && data[schema.modalValue[index]]
                          ? data[schema.modalValue[index]]
                          : ""
                      }
                      onChange={(text) => {
                        const copy = { ...data };
                        copy[schema.modalValue[index]] = text;
                        copy[schema.modalValue[index + 1]] = moment(text)
                          .add(7, "days")
                          .format("YYYY-MM-DD");
                        copy[schema.modalValue[index + 2]] = moment(text)
                          .add(9, "days")
                          .format("YYYY-MM-DD");
                        copy[schema.modalValue[index + 3]] = moment(text)
                          .add(11, "days")
                          .format("YYYY-MM-DD");
                        copy[schema.modalValue[index + 4]] = moment(text)
                          .add(46, "days")
                          .format("YYYY-MM-DD");
                        setNew(copy);
                      }}
                    />
                  </Flex>
                ) : (
                  <Flex key={index}>
                    <FormTextInput
                      status={false}
                      type={schema.type[index]}
                      label={item}
                      placeholder={schema.modalPlaceholder[index]}
                      value={
                        data && data[schema.modalValue[index]]
                          ? data[schema.modalValue[index]]
                          : ""
                      }
                      onChange={(text) => {
                        const copy = { ...data };
                        copy[schema.modalValue[index]] = text;
                        setNew(copy);
                      }}
                    />
                  </Flex>
                );
              })}
            </>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button color="white" bg="green.500" onClick={onClose}>
            Cancel
          </Button>
          <Spacer />
          <Button
            color="white"
            bg="green.500"
            onClick={() => {
              deleteData();
              onClose();
            }}
          >
            Delete
          </Button>
          <Spacer />
          <Button
            color="white"
            bg="green.500"
            onClick={() => {
              if (checkValidInput()) {
                saveData();
                onClose();
              }
            }}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
export default EditModal;
