import {
  ModalHeader,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalBody,
  ModalFooter,
  Button,
  Spacer,
  Flex,
  Toast,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import FormTextInput from "../textInput";
import { v4 as uuid } from "uuid";
import { db } from "../../firebase/firebase";
import { arrayUnion, doc, setDoc, updateDoc } from "firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import { setCounterRedux } from "../../redux/slices/counterSlice";

function CreateFarmModal({ isOpen, onClose }) {
  const newFarm = {
    modalHeading: " New Farm",
    modalLabel: [
      "Farm ID",
      "Farm Name",
      "Manager Password",
      "Employee Password",
    ],
    modalPlaceholder: [
      "Farm ID",
      "Farm Name",
      "Manager Password",
      "Employee Password",
    ],
    modalValue: ["farmID", "farmName", "managerPassword", "employeePassword"],
    farmPath: {
      farmID: "",
      farmName: "",
      managerPassword: "",
      employeePassword: "",
    },
  };

  const [newFarmProfile, setNewFarmProfile] = useState(newFarm.farmPath);
  const [cleanFarmProfile, setCleanFarmProfile] = useState(newFarm.farmPath);
  const { userID } = useSelector((state) => state.userIDSelected);
  const { userName } = useSelector((state) => state.userNameSelected);
  const { counter } = useSelector((state) => state.counterSelected);
  const toast = useToast();

  const dispatch = useDispatch();

  function showToast() {
    toast({
      title: "Farm created successfully!",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  }

  function showToastInput() {
    toast({
      title: "Insert Data in All Required Fields!",
      status: "warning",
      duration: 5000,
      isClosable: true,
    });
  }

  const checkValidInput = () => {
    if (
      newFarmProfile.farmID === "" ||
      newFarmProfile.farmName === "" ||
      newFarmProfile.managerPassword === "" ||
      newFarmProfile.employeePassword === ""
    ) {
      showToastInput();
      return false;
    }
    return true;
  };

  useEffect(() => {
    const createID = async () => {
      if (newFarmProfile.farmID === "") {
        const copy = { ...newFarmProfile };
        const id = uuid().slice(0, 6);
        copy.farmID = id;
        setNewFarmProfile(copy);
      }
    };
    createID().catch(console.error);
  }, [newFarmProfile, counter]);

  const createFarm = async (farmName) => {
    const farmNameRef = doc(db, "farms", farmName);

    try {
      await setDoc(doc(db, "farms/", farmName), newFarmProfile, {
        merge: true,
      });
      updateDoc(doc(db, "users/", userID), {
        farms: arrayUnion(farmName),
      });
      await setDoc(doc(farmNameRef, "employees", userID), {
        position: "manager",
        userName: userName,
        userID: userID,
      });
      dispatch(setCounterRedux(counter + 1));
      Toast("Farm Created");
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign="center">{newFarm.modalHeading}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <>
            <Flex display="flex" flexDir="column">
              <Flex display="flex" flexDir="column">
                {newFarm.modalLabel.map((item, index) => {
                  return newFarm.modalValue[index] === "farmID" ? (
                    <Flex key={index}>
                      <FormTextInput
                        status={true}
                        label={item}
                        placeholder={newFarm.modalPlaceholder[index]}
                        value={
                          newFarmProfile &&
                          newFarmProfile[newFarm.modalValue[index]]
                            ? newFarmProfile[newFarm.modalValue[index]]
                            : ""
                        }
                        onChange={(text) => {
                          const copy = { ...newFarmProfile };
                          copy[newFarm.modalValue[index]] = text;
                          setNewFarmProfile(copy);
                        }}
                      />
                    </Flex>
                  ) : (
                    <Flex key={index}>
                      <FormTextInput
                        status={false}
                        label={item}
                        placeholder={newFarm.modalPlaceholder[index]}
                        value={
                          newFarmProfile &&
                          newFarmProfile[newFarm.modalValue[index]]
                            ? newFarmProfile[newFarm.modalValue[index]]
                            : ""
                        }
                        onChange={(text) => {
                          const copy = { ...newFarmProfile };
                          copy[newFarm.modalValue[index]] = text;
                          setNewFarmProfile(copy);
                        }}
                      />
                    </Flex>
                  );
                })}
              </Flex>
            </Flex>
          </>
        </ModalBody>
        <ModalFooter>
          <Button bg="green.500" color="white" onClick={onClose}>
            Cancel
          </Button>
          <Spacer />
          <Button
            color="white"
            bg="green.500"
            onClick={() => {
              if (checkValidInput) {
                onClose();
                showToast();
                createFarm(newFarmProfile.farmName);
                setNewFarmProfile(cleanFarmProfile);
              }
            }}
          >
            Create
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default CreateFarmModal;
