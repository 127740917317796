import {
  ModalHeader,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalBody,
  ModalFooter,
  Button,
  Spacer,
  Flex,
  FormControl,
  FormLabel,
  Select,
  RadioGroup,
  HStack,
  Radio,
  useToast,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import FormTextInput from "../textInput";

function OverallModal({
  isOpen,
  onClose,
  data,
  addNew,
  newData,
  setNew,
  employeeData,
  setSelectedBatch,
}) {
  const typeOfAnimal = [
    "Cow",
    "Bull",
    "Calf",
    "Cow & Calf",
    "Pregnant Cow",
    "Cow in Insemination Protocol",
    "Milk Cow",
  ];
  const animalBreeds = [
    "Angus",
    "Hereford",
    "Shorthorn",
    "Simmental",
    "Nelore",
    "Jersey",
    "Holstein",
    "Charolais",
    "Limousin",
    "Ayrshire",
    "Guernsey",
    "Brown Swiss",
    "Dexter",
    "Zebu",
    "Other",
  ];
  const animalStatus = ["Alive", "Dead", "Sold"];
  const animalSex = ["Female", "Male", "Male & Female"];
  const yesNo = ["Yes", "No"];
  const sizeType = ["Acres", "Hectares", "Square Feet", "Square Meters"];
  const quantityType = [
    "Kilograms",
    "Grams",
    "Ounces",
    "Pounds",
    "Liters",
    "Milliliters",
    "Gallons",
  ];
  const vaccineType = ["Brucellosis", "Rabies", "Aftovaxpur"];
  const taskPriority = ["High", "Medium", "Low"];
  const profileGender = ["Male", "Female"];
  const { batchData } = useSelector((state) => state.batchDataSelected);
  const { pastureData } = useSelector((state) => state.pastureDataSelected);
  const toast = useToast();

  function showToastInput() {
    toast({
      title: "Insert Data in All Required Fields!",
      status: "warning",
      duration: 5000,
      isClosable: true,
    });
  }

  const checkValidInput = () => {
    if (
      newData.type === "" ||
      newData.batchNumber === "" ||
      newData.animalType === "" ||
      newData.numberOfAnimals === "" ||
      newData.batchAge === "" ||
      newData.pastureDivision === "" ||
      newData.age === "" ||
      newData.price === "" ||
      newData.breed === "" ||
      newData.sex === "" ||
      newData.calf === "" ||
      newData.motherTag === "" ||
      newData.weight === "" ||
      newData.weightCheck === "" ||
      newData.batch === "" ||
      newData.animalStatus === "" ||
      newData.tries === "" ||
      newData.name === "" ||
      newData.size === "" ||
      newData.sizeType === "" ||
      newData.capacity === "" ||
      newData.fName === "" ||
      newData.lName === "" ||
      newData.dob === "" ||
      newData.gender === "" ||
      newData.phone === "" ||
      newData.email === "" ||
      newData.item === "" ||
      newData.quantityType === "" ||
      newData.quantity === "" ||
      newData.reorderLevel === "" ||
      newData.checkedDate === "" ||
      newData.taskDate === "" ||
      newData.taskDueDate === "" ||
      newData.workToDo === "" ||
      newData.priority === "" ||
      newData.status === "" ||
      newData.personInCharge === "" ||
      newData.comment === "" ||
      newData.batch === "" ||
      newData.vaccineName === "" ||
      newData.vaccineBatch === "" ||
      newData.animalAge === "" ||
      newData.controlSex === "" ||
      newData.applicationPeriod === "" ||
      newData.animalAge === "" ||
      newData.vaccineSex === "" ||
      newData.description === ""
    ) {
      showToastInput();
      return false;
    }
    return true;
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign="center">{data.modalHeading}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex justify="center" direction="column">
            <>
              {data.modalLabel.map((item, index) => {
                return data.modalValue[index] === "personInCharge" ? (
                  <Flex key={index}>
                    <FormControl p="1vw" isRequired>
                      <FormLabel fontWeight="bold" fontSize="sm">
                        Employee in charge
                      </FormLabel>
                      <Select
                        placeholder="Select Employee"
                        variant="filled"
                        onChange={(e) => {
                          const copy = { ...newData };
                          copy[data.modalValue[index]] = e.target.value;
                          setNew(copy);
                        }}
                      >
                        {employeeData.map((item, index) => (
                          <option key={index}>{item.data.userName}</option>
                        ))}
                      </Select>
                    </FormControl>
                  </Flex>
                ) : data.modalValue[index] === "status" ? (
                  <Flex key={index}>
                    <FormControl p="1vw" isReadOnly={true} isRequired>
                      <FormLabel fontWeight="bold" fontSize="sm">
                        Task Status
                      </FormLabel>
                      <RadioGroup defaultValue="Not Completed">
                        <HStack spacing="24px">
                          <Radio
                            value="Completed"
                            onChange={(e) => {
                              const copy = { ...newData };
                              copy[data.modalValue[index]] = e.target.value;
                              setNew(copy);
                            }}
                          >
                            Completed
                          </Radio>
                          <Radio
                            value="Not Completed"
                            onChange={(e) => {
                              const copy = { ...data };
                              copy[data.modalValue[index]] = e.target.value;
                              setNew(copy);
                            }}
                          >
                            Not Completed
                          </Radio>
                        </HStack>
                      </RadioGroup>
                    </FormControl>
                  </Flex>
                ) : data.modalValue[index] === "pastureDivision" ? (
                  <Flex key={index}>
                    <FormControl p="1vw" isRequired>
                      <FormLabel fontWeight="bold" fontSize="sm">
                        Pasture Division
                      </FormLabel>
                      <Select
                        placeholder="Pasture Name"
                        variant="filled"
                        onChange={(e) => {
                          const copy = { ...newData };
                          copy[data.modalValue[index]] = e.target.value;
                          setNew(copy);
                        }}
                      >
                        {pastureData !== undefined
                          ? pastureData.map((item, index) => (
                              <option key={index}>{item}</option>
                            ))
                          : ""}
                      </Select>
                    </FormControl>
                  </Flex>
                ) : data.modalValue[index] === "sex" ||
                  data.modalValue[index] === "controlSex" ||
                  data.modalValue[index] === "vaccineSex" ? (
                  <Flex key={index}>
                    <FormControl p="1vw" isRequired>
                      <FormLabel fontWeight="bold" fontSize="sm">
                        Sex
                      </FormLabel>
                      <Select
                        placeholder="Animal Sex"
                        variant="filled"
                        onChange={(e) => {
                          const copy = { ...newData };
                          copy[data.modalValue[index]] = e.target.value;
                          setNew(copy);
                        }}
                      >
                        {animalSex.map((item, index) => (
                          <option key={index}>{item}</option>
                        ))}
                      </Select>
                    </FormControl>
                  </Flex>
                ) : data.modalValue[index] === "gender" ? (
                  <Flex key={index}>
                    <FormControl p="1vw" isRequired>
                      <FormLabel fontWeight="bold" fontSize="sm">
                        Gender
                      </FormLabel>
                      <Select
                        placeholder="Gender"
                        variant="filled"
                        onChange={(e) => {
                          const copy = { ...newData };
                          copy[data.modalValue[index]] = e.target.value;
                          setNew(copy);
                        }}
                      >
                        {profileGender.map((item, index) => (
                          <option key={index}>{item}</option>
                        ))}
                      </Select>
                    </FormControl>
                  </Flex>
                ) : data.modalValue[index] === "batch" ? (
                  <Flex key={index}>
                    <FormControl p="1vw" isRequired>
                      <FormLabel fontWeight="bold" fontSize="sm">
                        Batch
                      </FormLabel>
                      <Select
                        placeholder="Batch Number"
                        variant="filled"
                        onChange={(e) => {
                          const copy = { ...newData };
                          copy[data.modalValue[index]] = e.target.value;
                          setNew(copy);
                          setSelectedBatch(e.target.value);
                        }}
                      >
                        {batchData.map((item, index) => (
                          <option key={index}>{item.data.batchNumber}</option>
                        ))}
                      </Select>
                    </FormControl>
                  </Flex>
                ) : data.modalValue[index] === "breed" ? (
                  <Flex key={index}>
                    <FormControl p="1vw" isRequired>
                      <FormLabel fontWeight="bold" fontSize="sm">
                        Breed
                      </FormLabel>
                      <Select
                        placeholder="Animal Breed"
                        variant="filled"
                        onChange={(e) => {
                          const copy = { ...newData };
                          copy[data.modalValue[index]] = e.target.value;
                          setNew(copy);
                        }}
                      >
                        {animalBreeds.map((item, index) => (
                          <option key={index}>{item}</option>
                        ))}
                      </Select>
                    </FormControl>
                  </Flex>
                ) : data.modalValue[index] === "vaccineName" ? (
                  <Flex key={index}>
                    <FormControl p="1vw" isRequired>
                      <FormLabel fontWeight="bold" fontSize="sm">
                        Vaccine Name
                      </FormLabel>
                      <Select
                        placeholder="Vaccine Name"
                        variant="filled"
                        onChange={(e) => {
                          const copy = { ...newData };
                          copy[data.modalValue[index]] = e.target.value;
                          setNew(copy);
                        }}
                      >
                        {vaccineType.map((item, index) => (
                          <option key={index}>{item}</option>
                        ))}
                      </Select>
                    </FormControl>
                  </Flex>
                ) : data.modalValue[index] === "priority" ? (
                  <Flex key={index}>
                    <FormControl p="1vw" isRequired>
                      <FormLabel fontWeight="bold" fontSize="sm">
                        Task Priority
                      </FormLabel>
                      <Select
                        placeholder="Priority"
                        variant="filled"
                        onChange={(e) => {
                          const copy = { ...newData };
                          copy[data.modalValue[index]] = e.target.value;
                          setNew(copy);
                        }}
                      >
                        {taskPriority.map((item, index) => (
                          <option key={index}>{item}</option>
                        ))}
                      </Select>
                    </FormControl>
                  </Flex>
                ) : data.modalValue[index] === "calf" ? (
                  <Flex key={index}>
                    <FormControl p="1vw" isRequired>
                      <FormLabel fontWeight="bold" fontSize="sm">
                        Calf
                      </FormLabel>
                      <Select
                        value={newData.calf}
                        variant="filled"
                        onChange={(e) => {
                          const copy = { ...newData };
                          copy[data.modalValue[index]] = e.target.value;
                          setNew(copy);
                        }}
                      >
                        {yesNo.map((item, index) => (
                          <option key={index}>{item}</option>
                        ))}
                      </Select>
                    </FormControl>
                  </Flex>
                ) : data.modalValue[index] === "quantityType" ? (
                  <Flex key={index}>
                    <FormControl p="1vw" isRequired>
                      <FormLabel fontWeight="bold" fontSize="sm">
                        Measure Type
                      </FormLabel>
                      <Select
                        placeholder="Measure Type"
                        variant="filled"
                        onChange={(e) => {
                          const copy = { ...newData };
                          copy[data.modalValue[index]] = e.target.value;
                          setNew(copy);
                        }}
                      >
                        {quantityType.map((item, index) => (
                          <option key={index}>{item}</option>
                        ))}
                      </Select>
                    </FormControl>
                  </Flex>
                ) : data.modalValue[index] === "animalStatus" ? (
                  <Flex key={index}>
                    <FormControl p="1vw" isRequired>
                      <FormLabel fontWeight="bold" fontSize="sm">
                        Animal Status
                      </FormLabel>
                      <Select
                        value={newData.animalStatus}
                        variant="filled"
                        onChange={(e) => {
                          const copy = { ...newData };
                          copy[data.modalValue[index]] = e.target.value;
                          setNew(copy);
                        }}
                      >
                        {animalStatus.map((item, index) => (
                          <option key={index}>{item}</option>
                        ))}
                      </Select>
                    </FormControl>
                  </Flex>
                ) : data.modalValue[index] === "type" ||
                  data.modalValue[index] === "animalType" ? (
                  <Flex key={index}>
                    <FormControl p="1vw" isRequired>
                      <FormLabel fontWeight="bold" fontSize="sm">
                        Type
                      </FormLabel>
                      <Select
                        placeholder="Animal Type"
                        variant="filled"
                        onChange={(e) => {
                          const copy = { ...newData };
                          copy[data.modalValue[index]] = e.target.value;
                          setNew(copy);
                        }}
                      >
                        {typeOfAnimal.map((item, index) => (
                          <option key={index}>{item}</option>
                        ))}
                      </Select>
                    </FormControl>
                  </Flex>
                ) : data.modalValue[index] === "sizeType" ? (
                  <Flex key={index}>
                    <FormControl p="1vw" isRequired>
                      <FormLabel fontWeight="bold" fontSize="sm">
                        Size Type
                      </FormLabel>
                      <Select
                        placeholder=" Size Type"
                        variant="filled"
                        onChange={(e) => {
                          const copy = { ...newData };
                          copy[data.modalValue[index]] = e.target.value;
                          setNew(copy);
                        }}
                      >
                        {sizeType.map((item, index) => (
                          <option key={index}>{item}</option>
                        ))}
                      </Select>
                    </FormControl>
                  </Flex>
                ) : (
                  <Flex key={index}>
                    <FormTextInput
                      type={data.type[index]}
                      label={item}
                      placeholder={data.modalPlaceholder[index]}
                      value={
                        newData && newData[data.modalValue[index]]
                          ? newData[data.modalValue[index]]
                          : ""
                      }
                      onChange={(text) => {
                        const copy = { ...newData };
                        copy[data.modalValue[index]] = text;
                        setNew(copy);
                      }}
                    />
                  </Flex>
                );
              })}
            </>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button color="white" bg="green.500" onClick={onClose}>
            Cancel
          </Button>
          <Spacer />
          <Button
            color="white"
            bg="green.500"
            onClick={() => {
              if (checkValidInput()) {
                addNew();
                onClose();
              }
            }}
          >
            Add
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
export default OverallModal;
