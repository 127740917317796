import { Flex } from "@chakra-ui/react";
import { collection, doc, getDocs } from "firebase/firestore";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { db } from "../../firebase/firebase";
import { setBatchDataRedux } from "../../redux/slices/batchDataSlice";
import { setPastureDataRedux } from "../../redux/slices/pastureDataSlice";
import ContentComponent from "../ContentPage/ContentComponent";
import NavBar from "../Header";
import SideMenu from "../SideMenu";

const home = {
  heading: "Home",
  nagivate: ["New Farm", "Join Farm"],
};

const myTasks = {
  heading: "My Tasks",
  nagivate: ["All Tasks", "Completed", "Not Completed"],
};
const myFarm = {
  heading: "My Farm",
  nagivate: ["Employees", "Pasture Divisions", "Stock"],
};
const myAnimals = {
  heading: "My Animals",
  nagivate: ["Batchs", "Insemination Control", "Vaccine Control"],
};
const myDocuments = {
  heading: "My Documents",
  nagivate: ["Receipts", "Bills", "Invoices", "Legal Documents", "Others"],
};

function LandingPage() {
  const [selectedPage, setSelectedPage] = useState("home");
  const [indexTab, setIndexTab] = useState(0);
  const { farmName } = useSelector((state) => state.farmNameSelected);
  const dispatch = useDispatch();
  const { userID } = useSelector((state) => state.userIDSelected);
  const { counter } = useSelector((state) => state.counterSelected);

  useEffect(() => {
    if (userID !== "") {
      async function fetchData() {
        const farmNameRef = doc(db, "farms", farmName);
        const docRef = collection(farmNameRef, "batchs");
        const docRefPasture = collection(farmNameRef, "pastures");

        const docSnapBatch = await getDocs(docRef);
        const dataBatch = [];
        const addedBatchNumbers = new Set();

        docSnapBatch.forEach((doc) => {
          if (!addedBatchNumbers.has(doc.data().batchNumber)) {
            dataBatch.push({ id: doc.id, data: doc.data() });
            addedBatchNumbers.add(doc.data().batchNumber);
          }
        });
        dispatch(setBatchDataRedux(dataBatch));

        const docSnapPasture = await getDocs(docRefPasture);
        const dataPasture = [];
        const addedPasture = new Set();

        docSnapPasture.forEach((doc) => {
          if (!addedPasture.has(doc.data().name)) {
            dataPasture.push(doc.data().name);
            addedPasture.add(doc.data().name);
          }
        });
        dispatch(setPastureDataRedux(dataPasture));
      }
      setIndexTab(0);
      fetchData();
    }
  }, [farmName, userID, counter]);

  return (
    <Flex display="flex" flexDir="column" w="100%">
      <Flex
        position="fixed"
        left={0}
        top={0}
        bottom={0}
        width={250}
        justify="center"
        align="center"
      >
        <SideMenu selectPage={setSelectedPage} />
      </Flex>
      <Flex ml={250} p={10}>
        <Flex w="100%" display="flex" flexDir="column">
          <NavBar />
          <Flex minH="65vh">
            <ContentComponent
              content={
                selectedPage === "home"
                  ? home
                  : selectedPage === "myDocuments"
                  ? myDocuments
                  : selectedPage === "myAnimals"
                  ? myAnimals
                  : selectedPage === "myFarm"
                  ? myFarm
                  : selectedPage === "myTasks"
                  ? myTasks
                  : ""
              }
              indexTab={indexTab}
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default LandingPage;
