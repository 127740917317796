import { createSlice } from "@reduxjs/toolkit";

export const pastureDataSlice = createSlice({
  name: "pastureDataSelected",
  initialState: {
    pastureData: "",
  },
  reducers: {
    setPastureDataRedux: (state, action) => {
      state.pastureData = action.payload;
    },
  },
});

export const { setPastureDataRedux } = pastureDataSlice.actions;

export default pastureDataSlice.reducer;
