import { Button, Flex, Text, useDisclosure, useToast } from "@chakra-ui/react";
import { doc, getDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { db } from "../../firebase/firebase";
import CreateFarmModal from "./CreateFarmProfile";

function NewFarm() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentEmployeeData, setCurrentEmployeeData] = useState();
  const { farmName } = useSelector((state) => state.farmNameSelected);
  const { userID } = useSelector((state) => state.userIDSelected);
  const { counter } = useSelector((state) => state.counterSelected);
  const toast = useToast();

  function showToast() {
    toast({
      title: "Create Profile First on My Profile section!",
      status: "warning",
      duration: 5000,
      isClosable: true,
    });
  }

  useEffect(() => {
    if (userID !== "") {
      async function fetchData() {
        const userNameRef = doc(db, "users", userID);
        const docSnap = await getDoc(userNameRef);
        setCurrentEmployeeData(docSnap.data());
      }
      fetchData();
    }
  }, [farmName, userID, counter]);

  return (
    <>
      <Flex
        w="100%"
        mt="5"
        flexWrap="wrap"
        flexDir="column"
        bgRepeat="no-repeat"
        align="center"
        justify="center"
        bgSize="cover"
      >
        <Text fontSize="xl">
          Create a successful and sustainable farm business by making a detailed
          farm profile. It helps you make informed decisions and develop a clear
          vision for your farm's future.
        </Text>
        {userID !== "" ? (
          <Flex mt="5" display="flex" flexDir="column" align="center">
            <Text>Let's get started!</Text>
            <Button
              p="2"
              color="white"
              w="fit-content"
              h="fit-content"
              bg="green.500"
              onClick={() => {
                if (currentEmployeeData === undefined) {
                  showToast();
                } else {
                  onOpen();
                }
              }}
            >
              Create Farm
            </Button>
          </Flex>
        ) : (
          ""
        )}
      </Flex>
      <CreateFarmModal onClose={onClose} isOpen={isOpen} />
    </>
  );
}

export default NewFarm;
