import { createSlice } from "@reduxjs/toolkit";

export const userDataSlice = createSlice({
  name: "userDataSelected",
  initialState: {
    userData: "",
  },
  reducers: {
    setUserDataRedux: (state, action) => {
      state.userData = action.payload;
    },
  },
});

export const { setUserDataRedux } = userDataSlice.actions;

export default userDataSlice.reducer;
