import { Flex } from "@chakra-ui/react";
import LandingPage from "./components/Landing";

function App() {
  return (
    <Flex>
      <LandingPage data-testid="landing-page" />
    </Flex>
  );
}

export default App;
