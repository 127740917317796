import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  useDisclosure,
} from "@chakra-ui/react";
import { setDoc, doc, getDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import OverallModal from "../Modals/FormModal";
import { db } from "../../firebase/firebase";
import * as Moment from "moment";
import { extendMoment } from "moment-range";
import FormTextInput from "../textInput";
import { setCounterRedux } from "../../redux/slices/counterSlice";

const moment = extendMoment(Moment);
moment.locale = "en";

function Profile({ isOpen, onClose }) {
  const profile = {
    modalHeading: " New Profile",
    editHeading: " Edit Profile",
    modalLabel: [
      "First  Name",
      "Last Name",
      "Date of Birth",
      "Gender",
      "Phone Number",
      "Email",
      "Description",
    ],
    modalPlaceholder: [
      "First  Name",
      "Last Name",
      "Date of Birth",
      "Gender",
      "Phone Number",
      "Email",
      "Description",
    ],
    modalValue: [
      "fName",
      "lName",
      "dob",
      "gender",
      "phone",
      "email",
      "description",
    ],
    type: ["text", "text", "date", "text", "number", "email", "text"],
    employeePath: {
      fName: "",
      lName: "",
      dob: "",
      gender: "",
      phone: "",
      email: "",
      description: "N/A",
      farms: [],
    },
  };

  const profileGender = ["Male", "Female"];

  const {
    isOpen: isOpenCreate,
    onOpen: onOpenCreate,
    onClose: onCloseCreate,
  } = useDisclosure();
  const [newData, setNewData] = useState(profile.employeePath);
  const [currentEmployeeData, setCurrentEmployeeData] = useState(null);
  const { userID } = useSelector((state) => state.userIDSelected);
  const { farmName } = useSelector((state) => state.farmNameSelected);
  const { counter } = useSelector((state) => state.counterSelected);
  const dispatch = useDispatch();

  const addProfile = async () => {
    try {
      await setDoc(doc(db, "users", userID), newData);
      dispatch(setCounterRedux(counter + 1));
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  useEffect(() => {
    if (userID !== "") {
      async function fetchData() {
        const userNameRef = doc(db, "users", userID);
        const docSnap = await getDoc(userNameRef);
        setCurrentEmployeeData(docSnap.data());
      }
      fetchData();
    }
  }, [farmName, userID, counter]);

  const updateProfile = async () => {
    await setDoc(doc(db, "users", userID), newData);

    try {
      await setDoc(doc(db, "users", userID), currentEmployeeData, {
        merge: true,
      });

      dispatch(setCounterRedux(counter - 1));
      console.log("data was updated");
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader align="center">Profile</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex display="flex" flexDir="column">
              {currentEmployeeData !== undefined ? (
                <>
                  {profile.modalLabel.map((item, index) => {
                    return profile.modalValue[index] === "gender" ? (
                      <Flex key={index}>
                        <FormControl p="1vw" isRequired>
                          <FormLabel fontWeight="bold" fontSize="sm">
                            Gender
                          </FormLabel>
                          <Select
                            placeholder="Gender"
                            variant="filled"
                            value={
                              currentEmployeeData &&
                              currentEmployeeData[profile.modalValue[index]]
                                ? currentEmployeeData[profile.modalValue[index]]
                                : ""
                            }
                            onChange={(text) => {
                              const copy = { ...currentEmployeeData };
                              copy[profile.modalValue[index]] = text;
                              setCurrentEmployeeData(copy);
                            }}
                          >
                            <>
                              {profileGender.map((item, index) => (
                                <option key={index}>{item}</option>
                              ))}
                            </>
                          </Select>
                        </FormControl>
                      </Flex>
                    ) : (
                      <Flex key={index}>
                        <FormTextInput
                          status={false}
                          type={profile.type[index]}
                          label={item}
                          placeholder={profile.modalPlaceholder[index]}
                          value={
                            currentEmployeeData &&
                            currentEmployeeData[profile.modalValue[index]]
                              ? currentEmployeeData[profile.modalValue[index]]
                              : ""
                          }
                          onChange={(text) => {
                            const copy = { ...currentEmployeeData };
                            copy[profile.modalValue[index]] = text;
                            setCurrentEmployeeData(copy);
                          }}
                        />
                      </Flex>
                    );
                  })}
                </>
              ) : (
                <Flex justify="center">
                  <Button
                    width="fit-content"
                    color="white"
                    bg="green.500"
                    fontWeight="bold"
                    onClick={() => {
                      onOpenCreate();
                    }}
                  >
                    Create Profile
                  </Button>
                </Flex>
              )}
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button
              width="fit-content"
              color="white"
              bg="green.500"
              fontWeight="bold"
              onClick={() => {
                updateProfile();
                onClose();
              }}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <OverallModal
        isOpen={isOpenCreate}
        onClose={onCloseCreate}
        data={profile}
        addNew={addProfile}
        newData={newData}
        setNew={setNewData}
      />
    </>
  );
}
export default Profile;
