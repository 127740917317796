import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  Img,
  Stack,
  StackDivider,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { GiCow } from "react-icons/gi";
import * as Moment from "moment";
import { extendMoment } from "moment-range";

const moment = extendMoment(Moment);
moment.locale = "en";

const currentDate = moment().format("YYYY-MM-DD");

function BatchDataCard({ data, selectedBatch, setSelectedBatch, batch }) {
  const range = moment.range(data.data.batchAge, currentDate);

  return (
    <Flex width="100%" overflowX="scroll">
      <Card
        w="fit-content"
        mb="10"
        bg={selectedBatch === data.data.batchNumber ? "green.500" : "white"}
        color={selectedBatch === data.data.batchNumber ? "white" : "black"}
        onClick={() => {
          setSelectedBatch(data.data.batchNumber);
          batch(data.data.batchNumber);
        }}
      >
        <CardHeader>
          <Heading fontSize="lg">Batch: {data.data.batchNumber}</Heading>
        </CardHeader>
        <CardBody>
          <Stack divider={<StackDivider />}>
            <Box>
              <Text fontSize="xs" textTransform="uppercase">
                Age: {range.diff("months") + " months"}
              </Text>
            </Box>
            <Box>
              <Text fontSize="xs" textTransform="uppercase">
                Sex: {data.data.sex}
              </Text>
            </Box>
            <Box>
              <Text fontSize="xs" textTransform="uppercase">
                Type: {data.data.animalType}
              </Text>
            </Box>
            <Box>
              <Heading fontSize="xs" textTransform="uppercase">
                <Flex>
                  <GiCow size="25" />
                  <Text ml="5" mt="1">
                    {data.data.numberOfAnimals}
                  </Text>
                </Flex>
              </Heading>
            </Box>
          </Stack>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default BatchDataCard;
