import { createSlice } from "@reduxjs/toolkit";

export const userIDSlice = createSlice({
  name: "userIDSelected",
  initialState: {
    userID: "",
  },
  reducers: {
    setUserIDRedux: (state, action) => {
      state.userID = action.payload;
    },
  },
});

export const { setUserIDRedux } = userIDSlice.actions;

export default userIDSlice.reducer;
