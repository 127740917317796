import { Box, Button, Collapse, Flex, useDisclosure } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addDoc, collection, doc, getDocs } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import OverallModal from "../Modals/FormModal";
import { setCounterRedux } from "../../redux/slices/counterSlice";
import ResultTable from "../Modals/ResultTable";

function Vaccination() {
  const vaccine = {
    modalHeading: " New Vaccine",
    editHeading: "  Edit Vaccine",
    modalLabel: [
      "Name",
      "Application Period",
      "Age Range",
      "Sex",
      "Description",
    ],
    modalPlaceholder: [
      "Name",
      "Application Period in months",
      "Animal Age in months",
      "Sex",
      "Description",
    ],
    modalValue: [
      "vaccineName",
      "applicationPeriod",
      "animalAge",
      "vaccineSex",
      "description",
    ],
    type: ["text", "number", "text", "text", "text"],
    vaccinePath: {
      vaccineName: "",
      applicationPeriod: "",
      animalAge: "",
      vaccineSex: "",
      description: "N/A",
    },
  };

  const control = {
    modalHeading: " New Batch Control",
    editHeading: "  Edit Batch Control",
    modalLabel: [
      "Batch",
      "Vaccine Name",
      "Vaccine Batch",
      "Animals Age",
      "Sex",
      "Description",
    ],
    modalPlaceholder: [
      "Batch",
      "Vaccine Name",
      "Vaccine Batch",
      "Animal Age in months",
      "Sex",
      "Description",
    ],
    modalValue: [
      "batch",
      "vaccineName",
      "vaccineBatch",
      "animalAge",
      "controlSex",
      "description",
    ],
    type: ["number", "text", "text", "number", "text", "text"],
    controlPath: {
      batch: "",
      vaccineName: "",
      vaccineBatch: "",
      animalAge: "",
      controlSex: "",
      description: "N/A",
    },
  };
  const { isOpen, onToggle } = useDisclosure();

  const {
    isOpen: isOpenVaccine,
    onOpen: onOpenVaccine,
    onClose: onCloseVaccine,
  } = useDisclosure();
  const {
    isOpen: isOpenControl,
    onOpen: onOpenControl,
    onClose: onCloseControl,
  } = useDisclosure();

  const [newData, setNewData] = useState(vaccine.vaccinePath);
  const [newDataControl, setNewDataControl] = useState(control.controlPath);
  const [vaccinesData, setVaccinesData] = useState([]);
  const [controlData, setControlData] = useState([]);
  const { farmName } = useSelector((state) => state.farmNameSelected);
  const { counter } = useSelector((state) => state.counterSelected);
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchData() {
      if (farmName !== "") {
        const farmNameRef = doc(db, "farms", farmName);
        const docRefVaccines = collection(farmNameRef, "vaccines");
        const docRefControl = collection(farmNameRef, "vaccineControl");
        const docSnapVaccines = await getDocs(docRefVaccines);
        const docSnapControl = await getDocs(docRefControl);
        const dataVaccine = [];
        const dataControl = [];

        docSnapVaccines.forEach((doc) => {
          dataVaccine.push({ id: doc.id, data: doc.data() });
        });
        docSnapControl.forEach((doc) => {
          dataControl.push({ id: doc.id, data: doc.data() });
        });
        setVaccinesData(dataVaccine);
        setControlData(dataControl);
      }
    }
    fetchData();
  }, [farmName, counter]);

  const addVaccine = async () => {
    const farmNameRef = doc(db, "farms", farmName);
    const dbRef = collection(farmNameRef, "vaccines");
    try {
      await addDoc(dbRef, newData);
      dispatch(setCounterRedux(counter - 1));
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  const addControl = async () => {
    const farmNameRef = doc(db, "farms", farmName);
    const dbRef = collection(farmNameRef, "vaccineControl");
    try {
      await addDoc(dbRef, newDataControl);
      dispatch(setCounterRedux(counter + 1));
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  return (
    <>
      <Flex
        w="100%"
        flexWrap="wrap"
        flexDir="column"
        bgRepeat="no-repeat"
        bgSize="cover"
        bgColor="white"
      >
        <Flex
          flexWrap="wrap"
          flexDir="row"
          my="3"
          justifyContent="left"
          display="flex"
        >
          <Button
            width="fit-content"
            mx="1"
            my="1"
            color="white"
            bg="green.500"
            fontSize="13"
            fontWeight="bold"
            onClick={() => {
              onOpenVaccine();
            }}
          >
            New Vaccine +
          </Button>
          <Button
            width="fit-content"
            mx="1"
            my="1"
            color="white"
            bg="green.500"
            fontSize="13"
            fontWeight="bold"
            onClick={() => {
              onOpenControl();
            }}
          >
            New Batch Control +
          </Button>

          <Button
            color="white"
            onClick={onToggle}
            mx="1"
            my="1"
            fontSize="13"
            width="fit-content"
            bg="purple.500"
          >
            {isOpen ? "Hide Vaccines" : "Show Vaccines"}
          </Button>
        </Flex>
        <Flex
          w="100%"
          justifyContent="center"
          display="flex"
          flexDirection="column"
        >
          <Flex display="flex" justifyContent="center">
            <Collapse in={isOpen} animateOpacity>
              <Box
                w="fit-content"
                color="black"
                bg="white"
                rounded="md"
                shadow="md"
                my="10"
              >
                <ResultTable
                  w="100%"
                  schema={vaccine}
                  data={vaccinesData}
                  currentDoc={"vaccines"}
                />
              </Box>
            </Collapse>
          </Flex>
          <Flex
            w="100%"
            justifyContent="center"
            display="flex"
            flexDirection="column"
          >
            <ResultTable
              w="100%"
              schema={control}
              data={controlData}
              currentDoc={"vaccineControl"}
            />
          </Flex>
        </Flex>
      </Flex>
      <OverallModal
        isOpen={isOpenVaccine}
        onClose={onCloseVaccine}
        data={vaccine}
        addNew={addVaccine}
        newData={newData}
        setNew={setNewData}
      />
      <OverallModal
        isOpen={isOpenControl}
        onClose={onCloseControl}
        data={control}
        addNew={addControl}
        newData={newDataControl}
        setNew={setNewDataControl}
      />
    </>
  );
}

export default Vaccination;
