import {
  ModalHeader,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalBody,
  ModalFooter,
  Button,
  Spacer,
  Flex,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import FormTextInput from "../textInput";
import { db } from "../../firebase/firebase";
import {
  addDoc,
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import { setCounterRedux } from "../../redux/slices/counterSlice";

function JoinFarmModal({ isOpen, onClose }) {
  const joinFarm = {
    modalHeading: " Join New Farm",
    modalLabel: ["Farm ID", "Password"],
    modalPlaceholder: ["Farm ID", "Password"],
    modalValue: ["farmID", "password"],
    farmPath: {
      farmID: "",
      password: "",
    },
  };

  const [farmPosition, setFarmPosition] = useState();
  const [farmName, setFarmName] = useState();
  const [joinNewFarm, setJoinNewFarm] = useState(joinFarm.farmPath);
  const { userID } = useSelector((state) => state.userIDSelected);
  const { userName } = useSelector((state) => state.userNameSelected);
  const toast = useToast();
  const { counter } = useSelector((state) => state.counterSelected);
  const dispatch = useDispatch();
  function showToast() {
    toast({
      title: "Farm joined successfully!",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  }

  const updateFarmNamesUser = async (farmAdd, positionFarm) => {
    updateDoc(doc(db, "users", userID), {
      farms: arrayUnion(farmAdd),
    });
    addUserProfileToFarm(farmAdd, positionFarm);

    dispatch(setCounterRedux(counter + 1));
  };

  const addUserProfileToFarm = async (farmName, positionFarm) => {
    const farmNameRef = doc(db, "farms", farmName);

    try {
      await setDoc(doc(farmNameRef, "employees", userID), {
        position: positionFarm,
        userName: userName,
        userID: userID,
      });
      setFarmPosition(positionFarm);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  const addNewFarm = async () => {
    try {
      const q = query(
        collection(db, "farms"),
        where("farmID", "==", joinNewFarm.farmID)
      );

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((data) => {
        if (
          joinNewFarm.password === data.data().employeePassword ||
          joinNewFarm.password === data.data().managerPassword
        ) {
          if (joinNewFarm.password === data.data().employeePassword) {
            const farmNameRef = data.data().farmName;
            showToast();
            updateFarmNamesUser(farmNameRef, "employee");
          } else {
            const farmNameRef = data.data().farmName;
            showToast();
            updateFarmNamesUser(farmNameRef, "manager");
          }
        } else {
          toast({
            title: "Farm ID or password is incorrect!",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      });
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign="center">{joinFarm.modalHeading}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <>
              <Flex display="flex" flexDir="column">
                <Flex display="flex" flexDir="column">
                  {joinFarm.modalLabel.map((item, index) => (
                    <Flex key={index}>
                      <FormTextInput
                        label={item}
                        placeholder={joinFarm.modalPlaceholder[index]}
                        value={
                          joinNewFarm && joinNewFarm[joinFarm.modalValue[index]]
                            ? joinNewFarm[joinFarm.modalValue[index]]
                            : ""
                        }
                        onChange={(text) => {
                          const copy = { ...joinNewFarm };
                          copy[joinFarm.modalValue[index]] = text;
                          setJoinNewFarm(copy);
                        }}
                      />
                    </Flex>
                  ))}
                </Flex>
              </Flex>
            </>
          </ModalBody>
          <ModalFooter>
            <Button bg="green.500" color="white" onClick={onClose}>
              Cancel
            </Button>
            <Spacer />
            <Button
              color="white"
              bg="green.500"
              onClick={() => {
                onClose();
                addNewFarm();
              }}
            >
              Join
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default JoinFarmModal;
