import { Button, Flex, useDisclosure, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { db } from "../../firebase/firebase";
import { useDispatch, useSelector } from "react-redux";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import DocumentModal from "./NewDocument";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
} from "firebase/firestore";
import moment from "moment";
import { setCounterRedux } from "../../redux/slices/counterSlice";
import SingleFile from "./SingleFile";

const date = moment().format("YYYY-MM-DD");

function DocumentReceipts() {
  const documentInfo = {
    documentUrl: "",
    documentName: "",
    documentDate: date,
    // documentDesc: "",
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { farmName } = useSelector((state) => state.farmNameSelected);
  const { counter } = useSelector((state) => state.counterSelected);
  const { userID } = useSelector((state) => state.userIDSelected);
  const [filesData, setFilesData] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileInfo, setFileInfo] = useState(documentInfo);
  const dispatch = useDispatch();
  const storage = getStorage();
  const toast = useToast();
  function showToast() {
    toast({
      title: "Document Uploaded!",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  }

  useEffect(() => {
    async function fetchData() {
      const farmNameRef = doc(db, "farms", farmName);
      const docRef = doc(farmNameRef, "documents", "Receipts");
      const collectionRef = collection(docRef, "uploaded");
      const docSnap = await getDocs(collectionRef);
      const data = [];
      docSnap.forEach((doc) => {
        data.push({ id: doc.id, data: doc.data() });
      });
      setFilesData(data);
    }
    fetchData();
  }, [counter, userID, farmName]);

  const uploadFile = async (file, folderName) => {
    if (!file) return;
    try {
      // Uploading File to Firebase Storage
      const snapshot = await uploadBytesResumable(
        ref(storage, `/farms/${farmName}/documents/${folderName}/${file.name}`),
        file
      );

      // Getting Download Link
      const url = await getDownloadURL(
        ref(storage, `/farms/${farmName}/documents/${folderName}/${file.name}`)
      );

      // Updating File Info
      const copy = { ...fileInfo };
      copy.documentUrl = url;

      // Adding Document Info to Database
      await addDocumentInfo(folderName, copy);
    } catch (error) {
      console.error("Error uploading file: ", error);
    }
  };

  const addDocumentInfo = async (folder, fileInfoFromCopy) => {
    const farmNameRef = doc(db, "farms", farmName);
    const docRef = doc(farmNameRef, "documents", folder);
    const collectionRef = collection(docRef, "uploaded");
    try {
      await addDoc(collectionRef, fileInfoFromCopy);

      dispatch(setCounterRedux(counter + 1));
      showToast();
    } catch (e) {
      console.error("Error adding folder: ", e);
    }
  };

  const deleteDocument = async (id) => {
    const farmNameRef = doc(db, "farms", farmName);
    const docRef = doc(farmNameRef, "documents", "Receipts");
    const collectionRef = collection(docRef, "uploaded");
    try {
      await deleteDoc(doc(collectionRef, id + ""));
      dispatch(setCounterRedux(counter - 1));
    } catch (e) {
      console.error("Error deleting document: ", e);
    }
  };

  return (
    <>
      <Flex
        w="100%"
        flexWrap="wrap"
        flexDir="column"
        bgRepeat="no-repeat"
        bgSize="cover"
        bgColor="white"
      >
        <Flex
          flexWrap="wrap"
          flexDir="row"
          my="3"
          justifyContent="left"
          display="flex"
        >
          <Button
            width="fit-content"
            mx="1"
            my="1"
            color="white"
            bg="green.500"
            fontWeight="bold"
            fontSize="13"
            onClick={() => {
              onOpen();
            }}
          >
            New Document +
          </Button>
        </Flex>
        <Flex w="100%" justifyContent="center" flexWrap="wrap">
          {filesData.map((item, index) => {
            return (
              <Flex key={index} flexWrap="wrap" w="100%">
                <SingleFile fileData={item} deleteDoc={deleteDocument} />
              </Flex>
            );
          })}
        </Flex>
      </Flex>
      <DocumentModal
        isOpen={isOpen}
        onClose={onClose}
        setSelectedFile={setSelectedFile}
        selectedFile={selectedFile}
        uploadFile={uploadFile}
        fileInfo={fileInfo}
        setFileInfo={setFileInfo}
        selectedFolder={selectedFolder}
        setSelectedFolder={setSelectedFolder}
      />
    </>
  );
}

export default DocumentReceipts;
