import {
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { collection, deleteDoc, doc, setDoc } from "firebase/firestore";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { db } from "../../firebase/firebase";
import EditModal from "./EditModal";
import * as Moment from "moment";
import { extendMoment } from "moment-range";
import { setCounterRedux } from "../../redux/slices/counterSlice";

const moment = extendMoment(Moment);
moment.locale = "en";

const currentDate = moment().format("YYYY-MM-DD");

function ResultTable({
  schema,
  data,
  docData,
  currentUser,
  currentDoc,
  employeeData,
  batchN,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentRowData, setCurrentRowData] = useState({});
  const [currentRowID, setCurrentRowID] = useState("");
  const { farmName } = useSelector((state) => state.farmNameSelected);
  const { counter } = useSelector((state) => state.counterSelected);
  const dispatch = useDispatch();

  const updateRow = async () => {
    const farmNameRef = doc(db, "farms", farmName);
    const rowRef = collection(farmNameRef, currentDoc);
    try {
      await setDoc(doc(rowRef, currentRowID + ""), currentRowData, {
        merge: true,
      });
      dispatch(setCounterRedux(counter + 1));
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  const deleteRow = async () => {
    const farmNameRef = doc(db, "farms", farmName);
    const rowRef = collection(farmNameRef, currentDoc);
    try {
      await deleteDoc(doc(rowRef, currentRowID + ""));
      dispatch(setCounterRedux(counter - 1));
    } catch (e) {
      console.error("Error deleting document: ", e);
    }
  };

  return (
    <>
      <TableContainer>
        <Table
          fontSize="15"
          variant="simple"
          colorScheme="whatsapp"
          maxWidth="100%"
          overflow="overflowX"
          textColor="black"
        >
          <Thead>
            <Tr>
              {schema.modalLabel.map((item, index) => (
                <Th key={index}>{item}</Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {data.map((row, index) => {
              const rowValues = {};

              for (let i = 0; i < schema.modalValue.length; i++) {
                const label = schema.modalValue[i];
                rowValues[schema.modalValue[i]] = row.data[label];
              }
              return (
                <Tr
                  bg={
                    row.data.taskDueDate < currentDate &&
                    row.data.status !== "Completed"
                      ? "red.400"
                      : row.data.priority === "High" &&
                        row.data.status !== "Completed"
                      ? "purple.400"
                      : row.data.priority === "Medium" &&
                        row.data.status !== "Completed"
                      ? "purple.200"
                      : row.data.priority === "Low" &&
                        row.data.status !== "Completed"
                      ? "purple.100"
                      : row.data.status === "Completed"
                      ? "green.200"
                      : "white"
                  }
                  key={index}
                  onClick={() => {
                    onOpen();
                    setCurrentRowID(row.id);
                    setCurrentRowData(rowValues);
                  }}
                >
                  {Object.keys(rowValues).map((value, index) => {
                    const date = Object.values(rowValues)[index];

                    const range = moment.range(date, currentDate);

                    const format = moment(date).format("DD/MM/YYYY");

                    return value === "applicationPeriod" ||
                      value === "animalAge" ? (
                      <Td key={index} justifyItems="center">
                        {Object.values(rowValues)[index] + " months"}
                      </Td>
                    ) : value === "age" || value === "batchAge" ? (
                      <Td key={index} justifyItems="center">
                        {range.diff("months") + " months"}
                      </Td>
                    ) : value === "weightCheck" ||
                      value === "d0" ||
                      value === "d7" ||
                      value === "d9" ||
                      value === "d11" ||
                      value === "checkPregnance" ||
                      value === "taskDate" ||
                      value === "taskDueDate" ||
                      value === "age" ||
                      value === "weightCheck" ||
                      value === "dob" ||
                      value === "startDate" ||
                      value === "checkedDate" ? (
                      <Td key={index} justifyItems="center">
                        {format}
                      </Td>
                    ) : (
                      <Td key={index} justifyItems="center">
                        {Object.values(rowValues)[index]}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
      <EditModal
        isOpen={isOpen}
        onClose={onClose}
        schema={schema}
        currentUser={currentUser}
        data={currentRowData}
        setNew={setCurrentRowData}
        saveData={updateRow}
        deleteData={deleteRow}
        employeeData={employeeData}
        batchN={batchN}
      />
    </>
  );
}

export default ResultTable;
