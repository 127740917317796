import { Button } from "@chakra-ui/button";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { Image } from "@chakra-ui/image";
import { Flex, Heading, Text } from "@chakra-ui/layout";
import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/menu";
import { doc, getDoc } from "@firebase/firestore";
import React, { useEffect, useState } from "react";
import { MdOutlineWarehouse } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { db } from "../../firebase/firebase";
import { setFarmNameRedux } from "../../redux/slices/farmNameSlice";

function NavBar() {
  const [farmsName, setFarmsName] = useState([]);
  const { userID } = useSelector((state) => state.userIDSelected);
  const { userData } = useSelector((state) => state.userDataSelected);
  const { farmName } = useSelector((state) => state.farmNameSelected);
  const { counter } = useSelector((state) => state.counterSelected);
  const dispatch = useDispatch();

  const firstName = userData !== "" ? userData.displayName.split(" ")[0] : "";

  useEffect(() => {
    if (userID !== "") {
      async function fetchData() {
        const docRef = doc(db, "users", userID);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setFarmsName(docSnap.data().farms);
        } else {
          console.log("No such document!");
        }
      }
      fetchData();
    }
  }, [userID, farmName, counter]);

  return (
    <Flex
      justify={userID !== "" ? "space-between" : "center"}
      align="center"
      w="100%"
      p={4}
      flexWrap="wrap"
    >
      {userID !== "" ? (
        <>
          <Flex>
            <Flex display="flex" flexDir="row">
              <Image
                mt="5px"
                w="40px"
                h="40px"
                borderRadius="50%"
                src={userData.photoURL}
              />
              <Flex display="flex" flexDir="column" ml="5px">
                <Flex textStyle="bold" fontSize="30px">
                  Hello {firstName},
                </Flex>
                <Flex> Welcome back to Nelorandia!</Flex>
              </Flex>
            </Flex>
          </Flex>
          <Flex mt="3">
            <Menu>
              {({ isOpen }) => (
                <>
                  <Flex fontSize="4xl" mr="20px">
                    <MenuButton
                      bg="green.500"
                      color="white"
                      as={Button}
                      rightIcon={<ChevronDownIcon />}
                    >
                      {isOpen
                        ? farmName
                        : farmName === ""
                        ? "Select a Farm"
                        : farmName}
                    </MenuButton>
                    <Flex ml="5px">
                      <MdOutlineWarehouse />
                    </Flex>
                  </Flex>
                  {farmsName.length !== 0 ? (
                    <MenuList>
                      {farmsName &&
                        farmsName.map((name, index) => (
                          <MenuItem
                            key={index}
                            onClick={() => {
                              dispatch(setFarmNameRedux(name));
                            }}
                          >
                            {name}
                          </MenuItem>
                        ))}
                    </MenuList>
                  ) : (
                    <MenuList>
                      <MenuItem isDisabled>Join a farm first</MenuItem>
                    </MenuList>
                  )}
                </>
              )}
            </Menu>
          </Flex>
        </>
      ) : (
        <Flex display="flex" flexDir="column" justify="center" align="center">
          <Heading color="green.500">Welcome to Nelorandia!</Heading>
          <Text fontSize="xl">SignIn first to Start.</Text>
        </Flex>
      )}
    </Flex>
  );
}

export default NavBar;
