import { Button, Flex, useDisclosure } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addDoc, collection, doc, getDocs } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { setCounterRedux } from "../../redux/slices/counterSlice";
import ResultTable from "../Modals/ResultTable";
import OverallModal from "../Modals/FormModal";

function Pasture() {
  const pasture = {
    modalHeading: " New Pasture",
    editHeading: " Edit Pasture",
    modalLabel: ["Name", "Size", "Size Type", "Capacity", "Description"],
    modalPlaceholder: ["Name", "Size", "Size Type", "Capacity", "Description"],
    modalValue: ["name", "size", "sizeType", "capacity", "description"],
    type: ["text", "number", "text", "number", "text"],
    pasturePath: {
      name: "",
      size: "",
      sizeType: "",
      capacity: "",
      description: "",
    },
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [newData, setNewData] = useState(pasture.pasturePath);
  const [pastureData, setPastureData] = useState([]);
  const { farmName } = useSelector((state) => state.farmNameSelected);
  const { counter } = useSelector((state) => state.counterSelected);
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchData() {
      if (farmName !== "") {
        const farmNameRef = doc(db, "farms", farmName);
        const animalRef = collection(farmNameRef, "pastures");
        const docSnap = await getDocs(animalRef);
        const data = [];
        docSnap.forEach((doc) => {
          data.push({ id: doc.id, data: doc.data() });
        });
        setPastureData(data);
      }
    }
    fetchData();
  }, [farmName, counter]);

  const addPasture = async () => {
    const farmNameRef = doc(db, "farms", farmName);
    const docRef = collection(farmNameRef, "pastures");
    try {
      await addDoc(docRef, newData);
      dispatch(setCounterRedux(counter - 1));
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };
  return (
    <>
      <Flex
        w="100%"
        flexWrap="wrap"
        flexDir="column"
        bgRepeat="no-repeat"
        bgSize="cover"
        bgColor="white"
      >
        <Flex
          flexWrap="wrap"
          flexDir="row"
          my="3"
          justifyContent="left"
          display="flex"
        >
          <Button
            width="fit-content"
            mx="1"
            my="1"
            color="white"
            bg="green.500"
            fontSize="13"
            fontWeight="bold"
            onClick={() => {
              onOpen();
            }}
          >
            New Pasture +
          </Button>
        </Flex>
        <ResultTable
          w="100%"
          schema={pasture}
          data={pastureData}
          currentDoc={"pastures"}
        />
      </Flex>
      <OverallModal
        isOpen={isOpen}
        onClose={onClose}
        data={pasture}
        addNew={addPasture}
        newData={newData}
        setNew={setNewData}
      />
    </>
  );
}

export default Pasture;
