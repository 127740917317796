import { Button, Flex, useDisclosure } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { addDoc, collection, doc, getDocs } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { setCounterRedux } from "../../redux/slices/counterSlice";
import OverallModal from "../Modals/FormModal";
import ResultTable from "../Modals/ResultTable";

const currentDate = moment().format("YYYY-MM-DD");

function Stock() {
  const stock = {
    modalHeading: " New Item",
    editHeading: " Edit Item",
    modalLabel: [
      "Item Name",
      "Quantity Type",
      "Quantity",
      "Reorder Level",
      "Last Checked Date",
      "Description",
    ],
    modalPlaceholder: [
      "Item Name",
      "Quantity Type",
      "Quantity",
      "Reorder Level",
      "Last Checked Date",
      "Description",
    ],
    modalValue: [
      "item",
      "quantityType",
      "quantity",
      "reorderLevel",
      "checkedDate",
      "description",
    ],
    type: ["text", "text", "number", "number", "date", "text"],
    stockPath: {
      item: "",
      quantityType: "",
      quantity: "",
      reorderLevel: "",
      checkedDate: currentDate,
      description: "N/A",
    },
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [stocksData, setStocksData] = useState([]);
  const [newData, setNewData] = useState(stock.stockPath);
  const { farmName } = useSelector((state) => state.farmNameSelected);
  const { counter } = useSelector((state) => state.counterSelected);
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchData() {
      if (farmName !== "") {
        const farmNameRef = doc(db, "farms", farmName);
        const docRef = collection(farmNameRef, "stocks");
        const docSnap = await getDocs(docRef);
        const data = [];
        docSnap.forEach((doc) => {
          data.push({ id: doc.id, data: doc.data() });
        });
        setStocksData(data);
      }
    }
    fetchData();
  }, [counter, farmName]);

  const addStock = async () => {
    const farmNameRef = doc(db, "farms", farmName);
    const stockRef = collection(farmNameRef, "stocks");
    try {
      await addDoc(stockRef, newData);
      dispatch(setCounterRedux(counter - 1));
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  return (
    <>
      <Flex
        w="100%"
        flexWrap="wrap"
        flexDir="column"
        bgRepeat="no-repeat"
        bgSize="cover"
        bgColor="white"
      >
        <Flex
          flexWrap="wrap"
          flexDir="row"
          my="3"
          justifyContent="left"
          display="flex"
        >
          <Button
            width="fit-content"
            mx="1"
            my="1"
            color="white"
            fontSize="13"
            bg="green.500"
            fontWeight="bold"
            onClick={() => {
              onOpen();
            }}
          >
            New Item +
          </Button>
        </Flex>
        <ResultTable
          w="100%"
          schema={stock}
          data={stocksData}
          currentDoc={"stocks"}
        />
      </Flex>
      <OverallModal
        isOpen={isOpen}
        onClose={onClose}
        data={stock}
        addNew={addStock}
        newData={newData}
        setNew={setNewData}
      />
    </>
  );
}

export default Stock;
