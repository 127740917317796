import { createSlice } from "@reduxjs/toolkit";

export const userNameSlice = createSlice({
  name: "userNameSelected",
  initialState: {
    userName: "",
  },
  reducers: {
    setUserNameRedux: (state, action) => {
      state.userName = action.payload;
    },
  },
});

export const { setUserNameRedux } = userNameSlice.actions;

export default userNameSlice.reducer;
